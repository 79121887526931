import * as React from 'react';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableContainer from '@mui/material/TableContainer';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox, FormControlLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'username', label: 'Username', minWidth: 170 },
    { id: 'type', label: 'User Type', minWidth: 170 },
    { id: 'active', label: 'Active', minWidth: 80 },
    { id: 'update', label: 'Update', minWidth: 120, align: 'center' },
    { id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name, username, type, active) {
    return { id, name, username, type, active };
}

export default function ManageUsers() {

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [reloadData, setReloadData] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedOperators, setSelectedOperators] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [operators, setOperators] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [types, setTypes] = useState([]);
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({
        name: '',
        username: '',
        password: '',
        type_id: '',
        active: '',
        forced_change_password: true
    });
    const [selectedUserData, setSelectedUserData] = useState({
        id: '',
        name: '',
        username: '',
        password: '',
        type_id: '',
        active: '',
        forced_change_password: false
    });
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
	const [userToDeleteId, setUserToDeleteId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let route = 'user'
            let data = await apiCaller.getApiCall(route, '');
            let users = [];
            for (let user of data) {
                let newUserObject = {
                    id: user.id,
                    name: user.name,
                    username: user.username,
                    type_id: user.type_id,
                    type: await apiCaller.getApiCall('type', user.type_id),
                    permissions: await apiCaller.getApiCall(`${route}/permission`, user.id),
                    operators: await apiCaller.getApiCall(`${route}/operator`, user.id),
                    active: user.active
                };
                users.push(newUserObject);
            }
            setUsers(users);
            const permissions = await apiCaller.getApiCall('permission', '');
            const operators = await apiCaller.getApiCall('operator', '');
            const types = await apiCaller.getApiCall('type', '');
            setPermissions(permissions);
            setOperators(operators);
            setTypes(types);
            setLoading(false);
        };
        fetchData();
    }, [reloadData]);

    useEffect(() => {
        let filteredRows = users
            .filter(
                (user) =>
                    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.type.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((user) =>
                createData(
                    user.id,
                    user.name,
                    user.username,
                    user.type.name,
                    user.active
                )
            );

        setRows(filteredRows);
    }, [searchTerm, users]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAddUser = () => {
        setOpenAddUserDialog(true);
    }

    const handleUpdateUser = (id) => {
        const selectedUser = users.find((user) => user.id === id);
        setSelectedUserData(selectedUser);
        const userPermissionIds = selectedUser.permissions.map((permission) => permission.id);
        const userOperatorIds = selectedUser.operators.map((operator) => operator.id); 
        setSelectedPermissions(userPermissionIds);
        setSelectedOperators(userOperatorIds)
        setOpenUpdateUserDialog(true);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setNewUserData({
            name: '',
            username: '',
            password: '',
            type_id: '',
            active: '',
            forced_change_password: true
        });
        setSelectedUserData({
            id: '',
            name: '',
            username: '',
            password: '',
            type_id: '',
            active: '',
            forced_change_password: false
        })
        setOpenAddUserDialog(false);
        setOpenUpdateUserDialog(false);
    };

    const handleSaveUser = async () => {
        setAddLoading(true);
        const selectedOperatorsData = operators.filter(operator => selectedOperators.includes(operator.id));
        const selectedPermissionsData = permissions.filter(permission => selectedPermissions.includes(permission.id));
        let data = {
            user: newUserData,
            operators: selectedOperatorsData.map(operator => ({
                id: operator.id,
                name: operator.name,
                location: operator.location,
                country_id: operator.country_id,
                currency_id: operator.currency_id
            })),
            permissions: selectedPermissionsData.map(permission => ({
                id: permission.id,
                name: permission.name
            }))
        }
        let result = await apiCaller.postApiCall('user', data);
        if (result.error) {
            setOpenAddUserDialog(false);
        } else {
            handleReset();
            setAddLoading(false);
            setOpenAddUserDialog(false);
            setReloadData((prev) => !prev);
        }
    };

    const handleTogglePermission = (permissionId) => {
        setSelectedPermissions((prevSelectedPermissions) => {
            if (prevSelectedPermissions.includes(permissionId)) {
                return prevSelectedPermissions.filter((id) => id !== permissionId);
            } else {
                return [...prevSelectedPermissions, permissionId];
            }
        });
    };

    const handleToggleOperator = (operatorId) => {
        setSelectedOperators((prevSelectedOperators) => {
            if (prevSelectedOperators.includes(operatorId)) {
                return prevSelectedOperators.filter((id) => id !== operatorId);
            } else {
                return [...prevSelectedOperators, operatorId];
            }
        });
    };

    const handleUpdateSave = async () => {
		setAddLoading(true);
		const selectedOperatorsData = operators.filter(operator => selectedOperators.includes(operator.id));
        const selectedPermissionsData = permissions.filter(permission => selectedPermissions.includes(permission.id));
		let selectedUser = {
			id: selectedUserData.id,
			name: selectedUserData.name,
			username: selectedUserData.username,
			password: selectedUserData.password,
			type_id: selectedUserData.type_id,
            active: selectedUserData.active,
            forced_change_password: false
		}
		let data = {
			user: selectedUser,
			operators: selectedOperatorsData.map(operator => ({
				id: operator.id,
				name: operator.name,
				location: operator.location,
                country_id: operator.country_id,
                currency_id: operator.currency_id
			})),
            permissions: selectedPermissionsData.map(permission => ({
                id: permission.id,
                name: permission.name
            }))
		}
		let result = await apiCaller.putApiCall('user', data);
		if (result.error) {
			setOpenUpdateUserDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenUpdateUserDialog(false);
			setReloadData((prev) => !prev);
		}
	}

    const handleDeleteUser = (id) => {
		handleOpenDeleteConfirmation(id);
	};

    const handleOpenDeleteConfirmation = (id) => {
		setDeleteConfirmationOpen(true);
		setUserToDeleteId(id);
		setDeleteConfirmationText('');
	};

    const handleCloseDeleteConfirmation = () => {
		setDeleteConfirmationOpen(false);
		setUserToDeleteId(null);
		setDeleteConfirmationText('');
	};

    const handleConfirmDelete = async (id) => {
		await apiCaller.deleteApiCall('user', id)
		handleCloseDeleteConfirmation();
		setReloadData((prev) => !prev);
	};

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Users
            </Typography>
            <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={handleAddUser}
                sx={{ left: '90%', marginTop: 2 }}
            >
                <AddIcon sx={{ marginRight: 1 }} />
                Add
            </Button>
            <TableContainer sx={{ maxHeight: 440 }}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        width: '50%', // Make the TextField take the full width
                        marginTop: '15px', // Add bottom margin
                        marginLeft: '5px', // Add left margin
                        marginBottom: '10px'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            // Use Skeleton for the first row (header) during loading
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            // Render actual data when not loading
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id === 'active' ? (
                                                    <Checkbox checked={row.active} color="primary" />
                                                ) : column.id !== 'update' && column.id !== 'delete' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            column.id === 'update'
                                                                ? handleUpdateUser(row.id)
                                                                : handleDeleteUser(row.id)
                                                        }
                                                        color={column.id === 'update' ? 'primary' : 'error'}
                                                        variant="outlined"
                                                    >
                                                        {column.id === 'update' ? 'Update' : 'Delete'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={openAddUserDialog} onClose={() => setOpenAddUserDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step>
                            <StepLabel>User Data</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Permissions</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Operators</StepLabel>
                        </Step>
                    </Stepper>
                    <br></br>
                    {activeStep === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={newUserData.name}
                                    onChange={(e) => setNewUserData({ ...newUserData, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    value={newUserData.username}
                                    onChange={(e) => setNewUserData({ ...newUserData, username: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        labelId="type-label"
                                        id="type"
                                        value={newUserData.type_id}
                                        label="Type"
                                        onChange={(e) => setNewUserData({ ...newUserData, type_id: e.target.value })}
                                    >
                                        {types.map((type) => (
                                            <MenuItem key={type.id} value={type.id}>
                                                {`${type.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="active-label">Active</InputLabel>
                                    <Select
                                        labelId="active-label"
                                        id="active"
                                        value={newUserData.active}
                                        label="Currency"
                                        onChange={(e) => setNewUserData({ ...newUserData, active: e.target.value })}
                                    >
                                        <MenuItem key={'active'} value={true}>
                                            {`Active`}
                                        </MenuItem>
                                        <MenuItem key={'inactive'} value={false}>
                                            {`Inactive`}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Select Permissions</Typography>
                                {permissions.map((permission) => (
                                    <FormControlLabel
                                        key={permission.id}
                                        control={
                                            <Checkbox
                                                checked={selectedPermissions.includes(permission.id)}
                                                onChange={() => handleTogglePermission(permission.id)}
                                            />
                                        }
                                        label={permission.name}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 2 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Select Operators</Typography>
                                {operators.map((operator) => (
                                    <FormControlLabel
                                        key={operator.id}
                                        control={
                                            <Checkbox
                                                checked={selectedOperators.includes(operator.id)}
                                                onChange={() => handleToggleOperator(operator.id)}
                                            />
                                        }
                                        label={operator.name}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    {activeStep === 0 ? (
                        <Button onClick={() => handleReset()}>Cancel</Button>
                    ) : (
                        <Button onClick={handleBack}>Back</Button>
                    )}
                    <Button onClick={activeStep === 2 ? handleSaveUser : handleNext} color="primary">
                        {activeStep === 2 ? (
                            addLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Save'
                            )
                        ) : (
                            'Next'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUpdateUserDialog} onClose={() => setOpenUpdateUserDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
                <DialogTitle>Update User</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step>
                            <StepLabel>User Data</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Permissions</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Operators</StepLabel>
                        </Step>
                    </Stepper>
                    <br></br>
                    {activeStep === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={selectedUserData.name}
                                    onChange={(e) => setSelectedUserData({ ...selectedUserData, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    value={selectedUserData.username}
                                    onChange={(e) => setSelectedUserData({ ...selectedUserData, username: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        labelId="type-label"
                                        id="type"
                                        value={selectedUserData ? selectedUserData.type_id : ''}
                                        label="Type"
                                        onChange={(e) => setSelectedUserData({ ...selectedUserData, type_id: e.target.value })}
                                    >
                                        {types.map((type) => (
                                            <MenuItem key={type.id} value={type.id}>
                                                {`${type.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="active-label">Active</InputLabel>
                                    <Select
                                        labelId="active-label"
                                        id="active"
                                        value={selectedUserData.active}
                                        label="Currency"
                                        onChange={(e) => setSelectedUserData({ ...selectedUserData, active: e.target.value })}
                                    >
                                        <MenuItem key={'active'} value={true}>
                                            {`Active`}
                                        </MenuItem>
                                        <MenuItem key={'inactive'} value={false}>
                                            {`Inactive`}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Select Permissions</Typography>
                                {permissions.map((permission) => (
                                    <FormControlLabel
                                        key={permission.id}
                                        control={
                                            <Checkbox
                                                checked={selectedPermissions.includes(permission.id)}
                                                onChange={() => handleTogglePermission(permission.id)}
                                            />
                                        }
                                        label={permission.name}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 2 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Select Operators</Typography>
                                {operators.map((operator) => (
                                    <FormControlLabel
                                        key={operator.id}
                                        control={
                                            <Checkbox
                                                checked={selectedOperators.includes(operator.id)}
                                                onChange={() => handleToggleOperator(operator.id)}
                                            />
                                        }
                                        label={operator.name}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    {activeStep === 0 ? (
                        <Button onClick={() => handleReset()}>Cancel</Button>
                    ) : (
                        <Button onClick={handleBack}>Back</Button>
                    )}
                    <Button onClick={activeStep === 2 ? handleUpdateSave : handleNext} color="primary">
                        {activeStep === 2 ? (
                            addLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Save'
                            )
                        ) : (
                            'Next'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
				open={deleteConfirmationOpen}
				onClose={handleCloseDeleteConfirmation}
				sx={{ minWidth: 300 }}
			>
				<DialogTitle>Delete User</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this user?
					</Typography>
					<Typography>
						Type DELETE to confirm:
					</Typography>
					<TextField
						value={deleteConfirmationText}
						onChange={(e) => setDeleteConfirmationText(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
					<Button
						onClick={() => handleConfirmDelete(userToDeleteId)}
						color="error"
						disabled={deleteConfirmationText !== 'DELETE'}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
        </Paper>
    )
}