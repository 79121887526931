import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableContainer from '@mui/material/TableContainer';
import InputAdornment from '@mui/material/InputAdornment';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'name', label: 'Type', minWidth: 150 },
    { id: 'update', label: 'Update', minWidth: 120, align: 'center' },
    { id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name) {
    return { id, name };
}

export default function ManageTypes() {

    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [reloadData, setReloadData] = useState(false);
    const [rows, setRows] = useState([]);
    const [openAddTypeDialog, setOpenAddTypeDialog] = useState(false);
    const activeStep = 0;
    const [addLoading, setAddLoading] = useState(false);
    const [newTypeData, setNewTypeData] = useState({
        name: ''
    });
    const [selectedTypeData, setSelectedTypeData] = useState({
        id: '',
        name: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [typeToDeleteId, setTypeToDeleteId] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
    const [openUpdateTypeDialog, setOpenUpdateTypeDialog] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let route = 'type';
            let data = await apiCaller.getApiCall(route, '');
            let types = [];
            for (let type of data) {
                let newTypeObject = {
                    id: type.id,
                    name: type.name,
                };
                types.push(newTypeObject);
            }
            setTypes(types);
            setLoading(false);
        };
        fetchData();
    }, [reloadData]);

    useEffect(() => {
        let filteredRows = types
            .filter((type) =>
                type.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((type) =>
                createData(
                    type.id,
                    type.name,
                )
            );
        setRows(filteredRows);
    }, [searchTerm, types]);

    const handleAddType = () => {
        setOpenAddTypeDialog(true);
    };

    const handleSaveType = async () => {
        setAddLoading(true);
        let data = {
            name: newTypeData.name,
        };
        let result = await apiCaller.postApiCall('type', data);
        if (result.error) {
            setOpenAddTypeDialog(false);
        } else {
            handleReset();
            setAddLoading(false);
            setOpenAddTypeDialog(false);
            setReloadData((prev) => !prev);
        }
    }

    const handleReset = () => {
        setNewTypeData({
            name: ''
        });
        setOpenAddTypeDialog(false);
        setSelectedTypeData({
            id: '',
            name: ''
        });
    };

    const handleUpdateType = (id) => {
        const selectedType = types.find((type) => type.id === id);
        setSelectedTypeData(selectedType);
        setOpenUpdateTypeDialog(true);
    }

    const handleDeleteType = (id) => {
        handleOpenDeleteConfirmation(id);
    }

    const handleChangePage = (type, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (type) => {
        setRowsPerPage(+type.target.value);
        setPage(0);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setTypeToDeleteId(null);
        setDeleteConfirmationText('');
    };

    const handleOpenDeleteConfirmation = (typeId) => {
        setDeleteConfirmationOpen(true);
        setTypeToDeleteId(typeId);
        setDeleteConfirmationText('');
    };

    const handleConfirmDelete = async (id) => {
        await apiCaller.deleteApiCall('type', id)
        handleCloseDeleteConfirmation();
        setReloadData((prev) => !prev);
    };

    const handleUpdateSave = async () => {
        setAddLoading(true);
        let data = {
            id: selectedTypeData.id,
            name: selectedTypeData.name,
        }
        let result = await apiCaller.putApiCall('type', data);
        if (result.error) {
            setOpenUpdateTypeDialog(false);
        } else {
            handleReset();
            setAddLoading(false);
            setOpenUpdateTypeDialog(false);
            setReloadData((prev) => !prev);
        }
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Types
            </Typography>

            <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={handleAddType}
                sx={{ left: '90%', marginTop: 2 }}
            >
                <AddIcon sx={{ marginRight: 1 }} />
                Add
            </Button>

            <TableContainer sx={{ maxHeight: 440 }}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        width: '50%',
                        marginTop: '15px',
                        marginLeft: '5px',
                        marginBottom: '10px'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            // Use Skeleton for the first row (header) during loading
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            // Render actual data when not loading
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id !== 'update' && column.id !== 'delete' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            column.id === 'update'
                                                                ? handleUpdateType(row.id)
                                                                : handleDeleteType(row.id)
                                                        }
                                                        color={column.id === 'update' ? 'primary' : 'error'}
                                                        variant="outlined"
                                                    >
                                                        {column.id === 'update' ? 'Update' : 'Delete'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={openAddTypeDialog} onClose={() => setOpenAddTypeDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
                <DialogTitle>Add New Type</DialogTitle>
                <DialogContent>
                    <br></br>
                    {activeStep === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={newTypeData.name}
                                    onChange={(e) => setNewTypeData({ ...newTypeData, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => handleReset()}>Cancel</Button>

                    <Button onClick={handleSaveType} color="primary">
                        {addLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
				open={deleteConfirmationOpen}
				onClose={handleCloseDeleteConfirmation}
				sx={{ minWidth: 300 }}
			>
				<DialogTitle>Delete Type</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this type?
					</Typography>
					<Typography>
						Type DELETE to confirm:
					</Typography>
					<TextField
						value={deleteConfirmationText}
						onChange={(e) => setDeleteConfirmationText(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
					<Button
						onClick={() => handleConfirmDelete(typeToDeleteId)}
						color="error"
						disabled={deleteConfirmationText !== 'DELETE'}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

            <Dialog
				open={openUpdateTypeDialog}
				onClose={() => setOpenUpdateTypeDialog(false)}
				sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}
			>
				<DialogTitle>Update Type</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Name"
								value={selectedTypeData ? selectedTypeData.name : ''}
								onChange={(e) => setSelectedTypeData({ ...selectedTypeData, name: e.target.value })}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenUpdateTypeDialog(false)}>Cancel</Button>
					<Button onClick={() => handleUpdateSave(selectedTypeData)} color="primary">
						{addLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							'Update'
						)}
					</Button>
				</DialogActions>
			</Dialog>

        </Paper>
    )
}