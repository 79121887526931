import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import logo from '../img/FunCrashersLogo.png';
import { useNavigate } from 'react-router-dom';
import * as apiCaller from '../util/ApiCaller';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import DialogTitle from '@mui/material/DialogTitle';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                Funcrashers.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function AskForAccess() {

    const [permission, setPermission] = useState('');
    const [loaderDisplay, setDisplay] = useState('none');
    const [buttonDisplay, setButtonDisplay] = useState('block');
    const [messageDisplay, setMessageDisplay] = useState('none');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const handleClose = async () => {
        setOpen(false);
        await delay(1000);
        navigate('/');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisplay('flex');
        setButtonDisplay('none');
        const data = new FormData(event.currentTarget);
        if (!data.get('email').trim() || !data.get('fullname').trim() || !permission) {
            setDisplay('none');
            setMessageDisplay('block');
            setButtonDisplay('block');
            await delay(3000);
            setMessageDisplay('none');
        } else {
            let requestData = {
                email: data.get('email'),
                fullname: data.get('fullname'),
                permission: permission
            }
            let route = 'askforaccess';
            let res = await apiCaller.postApiCall(route, requestData);
            if (res.success) {
                setDisplay('none');
                setTitle('Success');
                setMessage(`An email was sent to our administrators.
                If you are granted access an email will be sent to the address you provide with more instructions.
                Thanks for getting into contact with us!`);
                setOpen(true);
            } else {
                setDisplay('none');
                setTitle('Error');
                setMessage(`An error occured while trying to get in contact with the administrators. 
                Please try again later.`);
                setOpen(true);
            }
        }
    };

    const handleChange = (event) => {
        setPermission(event.target.value);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    height: '70px',
                    width: '75%',
                    margin: 'auto',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginTop: '80px'
                }}
            />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            ></Box>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: '#21bbfe' }}>
                    <SettingsAccessibilityOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Access Request
                </Typography>
                <Box margin={2}>
                    <Typography component="p" variant="p">
                        Fill in your data to ask the manager to grant access.
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="fullname"
                        label="Full Name"
                        name="fullname"
                        autoComplete="fullname"
                        autoFocus
                    />
                    <FormControl sx={{ m: 0, marginTop: 2, minWidth: 400 }}>
                        <InputLabel id="select">Desired Permission</InputLabel>
                        <Select
                            labelId="slect-label"
                            id="select-id"
                            value={permission}
                            label="Desired Permission"
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Super User'}>Super User</MenuItem>
                            <MenuItem value={'Administrator'}>Administrator</MenuItem>
                            <MenuItem value={'Guest'}>Guest</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ display: buttonDisplay, mt: 3, mb: 2, backgroundColor: '#21bbfe' }}
                    >
                        Request Access
                    </Button>
                    <Box sx={{ display: loaderDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <CircularProgress sx={{ color: '#21bbfe' }} />
                    </Box>
                    <Box sx={{ display: messageDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                        <Typography component="p" sx={{ color: 'red' }}>* All fields are required</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/" variant="body2">
                                Back to Sign In
                            </Link>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}