import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { useEffect, useState, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TableContainer from '@mui/material/TableContainer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'date', label: 'Timestamp', minWidth: 100 },
    { id: 'roundId', label: 'Round ID', minWidth: 100 },
    { id: 'player', label: 'Player ID', minWidth: 50 },
    { id: 'game', label: 'Game', minWidth: 80 },
    { id: 'stats', label: 'Look Up', minWidth: 100, align: 'center' }
];

function createData(id, date, roundId, player, game) {
    return { id, date, roundId, player, game };
}

export default function Statistics() {

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const rowsPerPage = 100;
    const [rows, setRows] = useState([]);
    const [statistics, setStatsData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchedPagesRef = useRef([]);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (fetchedPagesRef.current.includes(actualPage)) {
                setLoading(false);
            } else {
                let route = `statistic/all/${actualPage}`;
                let data = await apiCaller.getApiCall(route, '');
                let statistics = []
                for (let stat of data.statistics) {
                    let newStatisticObject = {
                        id: stat.id,
                        datetime: stat.datetime,
                        round_id: stat.round_id,
                        player_id: stat.player_id,
                        statistics: stat.stats,
                        game: await apiCaller.getApiCall(`statistic/game/stat`, stat.id)
                    };
                    statistics.push(newStatisticObject);
                }
                setStatsData(prevStats => [...prevStats, ...statistics]);
                setTotalRecords(data.total);
                setLoading(false);
                fetchedPagesRef.current = [...fetchedPagesRef.current, actualPage];
            }
        };
        fetchData();
    }, [actualPage]);

    useEffect(() => {
        // Filter rows locally based on the already fetched data
        let filteredRows = statistics
            .map((statistic) =>
                createData(
                    statistic.id,
                    statistic.datetime,
                    statistic.round_id,
                    statistic.player_id,
                    statistic.game.name
                )
            );

        setRows(filteredRows);
    }, [searchTerm, statistics]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActualPage(newPage + 1);
    };

    const handleLookUpStatistics = (id) => {
        let dataSelected = statistics.find(stat => stat.id === id);
        let stats = JSON.parse(dataSelected.statistics);
        setSelectedRow({ data: dataSelected, statistics: stats });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDateChange = (date) => {
        
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Statistics
            </Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}
                        sx={{
                            width: '50%', // Make the TextField take the full width
                            marginTop: '45px', // Add bottom margin
                            marginLeft: '10px', // Add left margin
                            marginBottom: '15px'
                        }}
                    >
                        <DatePicker
                            label="Search by date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{ marginLeft: '10px', marginTop: '15px' }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            // Use Skeleton for the first row (header) during loading
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            // Render actual data when not loading
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id !== 'stats' && column.id !== 'delete' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() => handleLookUpStatistics(row.id)}
                                                        color={'primary'}
                                                        variant="outlined"
                                                    >
                                                        {'Look Up'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={100}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count} (${Math.ceil(count / rowsPerPage)} pages)`;
                }}
            />
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Statistics Details</DialogTitle>
                <DialogContent>
                    {/* Render additional insights and stats using the selectedRow data */}
                    {selectedRow && (
                        <Grid container spacing={1}>
                            {/* General Information Column */}
                            <Grid item xs={5}>
                                <Typography component="h1" variant="h6">General Information</Typography>
                                <p>ID: {selectedRow.data.id}</p>
                                <p>Timestamp: {selectedRow.data.datetime}</p>
                                <p>Round ID: {selectedRow.data.round_id}</p>
                                <p>Game: {selectedRow.data.game.name}</p>
                                <p>Player: {selectedRow.data.player_id}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component="h1" variant="h6">Detailed Statistics</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <p>Main Bet: {selectedRow.statistics.main_bet_amount}</p>
                                        <p>Height Bet: {selectedRow.statistics.height_bet_amount}</p>
                                        <p>Scenario Bet: {selectedRow.statistics.scenario_bet_amount}</p>
                                        <p>Multiplier: {selectedRow.statistics.player_multiplier}</p>
                                        <p>Height: {selectedRow.statistics.player_height}</p>
                                        <p>Scenario: {selectedRow.statistics.player_scenario}</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <p>Cash Out: {selectedRow.statistics.cash_out_amount}</p>
                                        <p>Bonus: {selectedRow.statistics.bonus_amount}</p>
                                        <p>Start: {selectedRow.statistics.started_time}</p>
                                        <p>End: {selectedRow.statistics.end_time}</p>
                                        <p>Round Multiplier: {selectedRow.statistics.round_multiplier}</p>
                                        <p>Round Height: {selectedRow.statistics.round_height}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>

    );
}