import axios from 'axios';
import * as envVarReader from './EnvVarReader';

export const postApiCall = async (route, data) => {
    try {
        let token = envVarReader.APIKEY;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        let response = await axios.post(`${envVarReader.apiBaseUrl}${route}`, data, { headers });
        return response.data;
    } catch (err) {
        return {
            error: err,
            success: false
        }
    }
}

export const putApiCall = async (route, data) => {
    try {
        let token = envVarReader.APIKEY;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        let response = await axios.put(`${envVarReader.apiBaseUrl}${route}`, data, { headers });
        return response.data;
    } catch (err) {
        return {
            error: err,
            success: false
        }
    }
}

export const getApiCall = async (route, params) => {
    try {
        let token = envVarReader.APIKEY;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        let response = await axios.get(`${envVarReader.apiBaseUrl}${route}/${params}`, { headers });
        return response.data;
    } catch (err) {
        return {
            error: err,
            success: false
        }
    }
}

export const deleteApiCall = async (route, params) => {
    try {
        let token = envVarReader.APIKEY;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        let response = await axios.delete(`${envVarReader.apiBaseUrl}${route}/${params}`, { headers });
        return response.data;
    } catch (err) {
        return {
            error: err,
            success: false
        }
    }
}