import Header from './Header';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import * as apiCaller from '../../util/ApiCaller';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as encryptionAndComparison from '../../util/EncryptionAndComparison';

export default function UserProfile() {

    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [type, setType] = useState('')
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [editingEnabled, setEditingEnabled] = useState(false);
    const location = useLocation();
    const user = location.state ? location.state : null;
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState(null);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const loadUserData = () => {
        if (user) {
            setLoading(false);
            setUsername(user.user.username);
            setType(user.type.name);
            setName(user.user.name);
        } else {
            navigate('/');
        }

    };

    useEffect(() => {
        loadUserData();
    }, []);

    const handleProfileSubmit = async (e) => {
        e.preventDefault();
        user.user.name = name;
        user.user.username = username;
        let data = {
            user: user.user,
            operators: user.operators,
            permissions: user.permissions
        }
        await apiCaller.putApiCall('user', data);
        setEditingEnabled(false);
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        /* 
            lets encrypt the new old pass and compare texts on FE 
            if both are equal, then we have to compare if both new and confirmed are equal. 
            lets create a utility to encrypt and compare.
        */
        if (await encryptionAndComparison.encryptAndCompare(oldPassword, user.user.password)) {
            if (newPassword === confirmNewPassword) {
                user.user.password = newPassword;
                let data = {
                    user: user.user,
                    operators: user.operators,
                    permissions: user.permissions,
                    update: true
                }
                await apiCaller.putApiCall('user', data);
                setSuccessDialogOpen(true);
            } else {
                showMessage('New password and confirmation do not match.');
            }
        } else {
            showMessage('The current password you provided does not match our records.');
        }
    };

    const showMessage = (msg) => {
        setMessage(msg);
        setTimeout(() => {
            setMessage(null);
        }, 4000);
    };

    const toggleShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleCloseSuccessDialog = () => {
        navigate('/');
    };

    if (loading) {
        return null;
    }

    return (
        <div>
            <Header />
            <Typography sx={{ marginTop: '4em', fontWeight: 'bold' }} variant="h4" gutterBottom align="center">
                User Profile
            </Typography>
            <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 4 }}>
                <Grid container spacing={3}>
                    {/* Section for user information */}
                    <Grid item xs={6}>
                        <Box sx={{ borderRight: '1px solid #ccc', paddingRight: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                User Information
                            </Typography>
                            <TextField
                                label="User Type"
                                fullWidth
                                value={type}
                                disabled={true}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Name"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={!editingEnabled}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Username"
                                fullWidth
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                disabled={!editingEnabled}
                                sx={{ mb: 2 }}
                            />
                            {editingEnabled && (
                                <Button variant="contained" color="primary" onClick={handleProfileSubmit}>
                                    Apply Changes
                                </Button>
                            )}
                            {!editingEnabled && (
                                <Button variant="contained" onClick={() => setEditingEnabled(true)}>
                                    Enable Editing
                                </Button>
                            )}
                        </Box>
                    </Grid>
                    {/* Section for changing password */}
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Change Password
                            </Typography>
                            <TextField
                                label="Current Password"
                                fullWidth
                                type={showOldPassword ? 'text' : 'password'}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                autoComplete="new-password"
                                sx={{ mb: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={toggleShowOldPassword}>
                                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </Button>
                                    ),
                                }}
                            />
                            <TextField
                                label="New Password"
                                fullWidth
                                type={showNewPassword ? 'text' : 'password'}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                sx={{ mb: 2 }}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={toggleShowNewPassword}>
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </Button>
                                    ),
                                }}
                            />
                            <TextField
                                label="Confirm New Password"
                                fullWidth
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                sx={{ mb: 2 }}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={toggleShowConfirmPassword}>
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </Button>
                                    ),
                                }}
                            />
                            <Button onClick={handlePasswordSubmit} variant="contained" color="primary" type="submit">
                                Change Password
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {message && (
                <div style={{ color: 'red', marginTop: '1em', textAlign: 'center' }}>
                    {message}
                </div>
            )}
            <Dialog open={successDialogOpen} onClose={handleCloseSuccessDialog} disableEscapeKeyDown disableBackdropClick>
                <DialogTitle>Password Changed Successfully</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Your password has been changed successfully.
                        Please proceed to log in again using the new password.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccessDialog} color="primary" autoFocus>
                        Proceed to Login
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
