import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import logo from '../img/FunCrashersLogo.png';
import { useNavigate } from 'react-router-dom';
import * as apiCaller from '../util/ApiCaller';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import DialogContentText from '@mui/material/DialogContentText';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                Funcrashers.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function ForgotPassword() {

    const [loaderDisplay, setLoaderDisplay] = useState('none');
    const [buttonDisplay, setButtonDisplay] = useState('inline-flex');
    const [messageDisplay, setMessageDisplay] = useState('none');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoaderDisplay('inline-flex');
        setButtonDisplay('none');
        const data = new FormData(event.currentTarget);
        if (!data.get('email').trim()) {
            setButtonDisplay('inline-flex');
            setLoaderDisplay('none');
            setMessageDisplay('block');
            await delay(3000);
            setMessageDisplay('none');
        } else {
            let apiData = {
                username: data.get('email')
            };
            let route = 'user/forgotpassword'
            let res = await apiCaller.postApiCall(route, apiData);
            if (res.success) {
                setLoaderDisplay('none');
                setTitle('Success');
                setMessage(`An email was sent to your address.
                Please, read instructions carefully.
                If after reseting your password, still found problems getting into your account, please reach out support from the administrators.`);
                setOpen(true);
            } else {
                setLoaderDisplay('none');
                setTitle('Error');
                setMessage(`An error occured while trying to reset your password. 
                Please try again later.`);
                setOpen(true);
            }
        }
    };

    const handleClose = async () => {
        setOpen(false);
        await delay(1000);
        navigate('/');
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    height: '70px',
                    width: '75%',
                    margin: 'auto',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginTop: '80px'
                }}
            />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            ></Box>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: '#21bbfe' }}>
                    <KeyOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Forgot Password
                </Typography>
                <Box margin={2}>
                    <Typography component="p" variant="p">
                        Fill in with your associated email address.
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        sx={{ width: '382px' }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ display: buttonDisplay, mt: 3, mb: 2, backgroundColor: '#21bbfe', width: '382px' }}
                    >
                        Send Email
                    </Button>
                    <Box sx={{ display: loaderDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '382px' }}>
                        <CircularProgress sx={{ color: '#21bbfe' }} />
                    </Box>
                    <Box sx={{ display: messageDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                        <Typography component="p" sx={{ color: 'red' }}>* Email address field is required</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/" variant="body2">
                                Back to Sign In
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}