import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import logo from '../img/FunCrashersLogo.png';
import * as apiCaller from '../util/ApiCaller';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import InsertChartIcon from '@mui/icons-material/InsertChart';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                Funcrashers.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function StatisticsLookUp() {

    const loaderDisplay = 'none';
    const buttonDisplay = 'block';
    const [messageDisplay, setMessageDisplay] = useState('none');
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [title, setTitle] = useState('');
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const playerId = formData.get('playerid');
        const gameId = formData.get('gameid');
        const roundId = formData.get('roundid');

        // Check if any field is empty
        if (!playerId || !gameId || !roundId) {
            setMessageDisplay('flex');
            await delay(3000);
            setMessageDisplay('none');
            return;
        }
        setLoading(true);
        setOpen(true);
        let stat = await apiCaller.getApiCall('statistic/round', roundId);
        if (stat && stat.length !== 0) {
            let statistic = stat[0];
            let game = await apiCaller.getApiCall('game', statistic.game_id);
            let stats = JSON.parse(statistic.stats);
            setTitle(`Round ${statistic.round_id}`);
            setDialogMessage(<>
                <Grid container spacing={1}>
                    {/* General Information Column */}
                    <Grid item xs={5}>
                        <Typography component="h1" variant="h6">General Information</Typography>
                        <p>ID: {statistic.id}</p>
                        <p>Timestamp: {statistic.datetime}</p>
                        <p>Round ID: {statistic.round_id}</p>
                        <p>Game: {game.name}</p>
                        <p>Player: {statistic.player_id}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component="h1" variant="h6">Detailed Statistics</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <p>Main Bet: {stats.main_bet_amount}</p>
                                <p>Height Bet: {stats.height_bet_amount}</p>
                                <p>Scenario Bet: {stats.scenario_bet_amount}</p>
                                <p>Multiplier: {stats.player_multiplier}</p>
                                <p>Height: {stats.player_height}</p>
                                <p>Scenario: {stats.player_scenario}</p>
                            </Grid>
                            <Grid item xs={5}>
                                <p>Cash Out: {stats.cash_out_amount}</p>
                                <p>Bonus: {stats.bonus_amount}</p>
                                <p>Start: {stats.started_time}</p>
                                <p>End: {stats.end_time}</p>
                                <p>Round Multiplier: {stats.round_multiplier}</p>
                                <p>Round Height: {stats.round_height}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>);
        } else {
            setTitle('No Results Found');
            setDialogMessage(`We did not find any results for the Round ID: ${roundId} provided for the specified game or player. Please review the data and try again or contact support.`);
        }
        console.log('setting loading to false');
        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    height: '70px',
                    width: '75%',
                    margin: 'auto',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginTop: '80px'
                }}
            />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            ></Box>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: '#21bbfe' }}>
                    <InsertChartIcon />
                </Avatar>
                <Typography component="h1" variant="h4">
                    Request Round Results
                </Typography>
                <Box margin={2}>
                    <Typography component="p" variant="p">
                        Fill in the data to request a specific round result
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="playerid"
                        label="Player ID"
                        name="playerid"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="gameid"
                        label="Game ID"
                        name="gameid"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="roundid"
                        label="Round ID"
                        name="roundid"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ display: buttonDisplay, mt: 3, mb: 2, backgroundColor: '#21bbfe' }}
                    >
                        Request Results
                    </Button>
                    <Box sx={{ display: loaderDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <CircularProgress sx={{ color: '#21bbfe' }} />
                    </Box>
                    <Box sx={{ display: messageDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                        <Typography component="p" sx={{ color: 'red' }}>* All fields are required</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/" variant="body2">
                                Back to Sign In
                            </Link>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                {loading === false ?
                    <>
                        <DialogTitle id="responsive-dialog-title">
                            {title}
                        </DialogTitle>
                        <DialogContent style={{ minWidth: '600px', minHeight: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <DialogContentText>
                                {dialogMessage}
                            </DialogContentText>
                        </DialogContent>
                    </> :
                    <>
                        <DialogContent style={{ minWidth: '600px', minHeight: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress style={{ width: '200px', height: '200px' }} />
                        </DialogContent>
                    </>
                }
                <DialogActions>
                    <Button disabled={loading} onClick={handleClose} autoFocus>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}