import * as React from 'react';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import { Checkbox, FormControlLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
	{ id: 'id', label: 'ID', minWidth: 50 },
	{ id: 'name', label: 'Name', minWidth: 150 },
	{ id: 'location', label: 'Location', minWidth: 150 },
	{ id: 'country', label: 'Country', minWidth: 150 },
	{ id: 'currency', label: 'Currency', minWidth: 80 },
	{ id: 'operator_id', label: 'Operator ID', minWidth: 100 },
	{ id: 'update', label: 'Update', minWidth: 120, align: 'center' },
	{ id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name, location, country, currency, operator_id) {
	return { id, name, location, country, currency, operator_id };
}

export default function ManageOperators() {

	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rows, setRows] = useState([]);
	const [operators, setOperators] = useState([]);
	const [openAddOperatorDialog, setOpenAddOperatorDialog] = useState(false);
	const [newOperatorData, setNewOperatorData] = useState({
		name: '',
		location: '',
		country_id: '',
		currency_id: '',
		operator_id: '',
		bank_id: '',
		upper_limit: '',
		bottom_limit: ''
	});
	const [currencies, setCurrencies] = useState([]);
	const [countries, setCountries] = useState([]);
	const [games, setGames] = useState([]);
	const [addLoading, setAddLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [selectedGames, setSelectedGames] = useState([]);
	const [reloadData, setReloadData] = useState(false);
	const [openUpdateOperatorDialog, setOpenUpdateOperatorDialog] = useState(false);
	const [clients, setClients] = useState([]);
	const [selectedOperatorData, setSelectedOperatorData] = useState({
		id: '',
		name: '',
		location: '',
		country_id: '',
		currency_id: '',
		operator_id: '',
		bank_id: '',
		upper_limit: '',
		bottom_limit: ''
	});
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
	const [operatorToDeleteId, setOperatorToDeleteId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			let route = 'operator';
			let data = await apiCaller.getApiCall(route, '');
			let operators = []
			for (let operator of data) {
				let newOperatorObject = {
					id: operator.id,
					name: operator.name,
					location: operator.location,
					country_id: operator.country_id,
					currency_id: operator.currency_id,
					operator_id: operator.operator_id,
					bank_id: operator.bank_id,
					bottom_limit: operator.bottom_limit,
					upper_limit: operator.upper_limit,
					country: await apiCaller.getApiCall('country', operator.country_id),
					currency: await apiCaller.getApiCall('currency', operator.currency_id),
					games: await apiCaller.getApiCall(`${route}/game`, operator.id)
				};
				operators.push(newOperatorObject);
			}
			setOperators(operators);
			const currencyData = await apiCaller.getApiCall('currency', '');
			setCurrencies(currencyData);
			const countryData = await apiCaller.getApiCall('country', '');
			setCountries(countryData);
			const gamesData = await apiCaller.getApiCall('game', '');
			setGames(gamesData);
			const clientsData = await apiCaller.getApiCall('client', '');
			setClients(clientsData)
			setLoading(false);
		};
		fetchData();
	}, [reloadData]);

	useEffect(() => {
		// Filter rows locally based on the already fetched data
		console.log('these are the operators to filter', JSON.stringify(operators));
		let filteredRows = operators
			.filter(
				(operator) =>
					operator.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					operator.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
					operator.country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					operator.operator_id.toString().includes(searchTerm) ||
					`${operator.currency.name} ${operator.currency.code} ${operator.currency.symbol}`.toLowerCase().includes(
						searchTerm.toLowerCase()
					)
			)
			.map((operator) =>
				createData(
					operator.id,
					operator.name,
					operator.location,
					operator.country.name,
					`${operator.currency.name} ${operator.currency.code} ${operator.currency.symbol}`,
					operator.operator_id
				)
			);

		setRows(filteredRows);
	}, [searchTerm, operators]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleAddOperator = () => {
		setOpenAddOperatorDialog(true);
	};

	const handleDeleteOperator = (id) => {
		handleOpenDeleteConfirmation(id);
	};

	const handleOpenDeleteConfirmation = (operatorId) => {
		setDeleteConfirmationOpen(true);
		setOperatorToDeleteId(operatorId);
		setDeleteConfirmationText('');
	};

	const handleConfirmDelete = async (id) => {
		await apiCaller.deleteApiCall('operator', id)
		handleCloseDeleteConfirmation();
		setReloadData((prev) => !prev);
	};

	const handleCloseDeleteConfirmation = () => {
		setDeleteConfirmationOpen(false);
		setOperatorToDeleteId(null);
		setDeleteConfirmationText('');
	};

	const handleUpdateOperator = (id) => {
		const selectedOperator = operators.find((operator) => operator.id === id);
		setSelectedOperatorData(selectedOperator);
		const operatorGameIds = selectedOperator.games.map((game) => game.id);
		setSelectedGames(operatorGameIds);
		setOpenUpdateOperatorDialog(true);
	};

	const handleSaveOperator = async () => {
		setAddLoading(true);
		const selectedGamesData = games.filter(game => selectedGames.includes(game.id));
		let data = {
			operator: newOperatorData,
			games: selectedGamesData.map(game => ({
				id: game.id,
				name: game.name,
				description: game.description,
			})),
		}
		let result = await apiCaller.postApiCall('operator', data);
		if (result.error) {
			setOpenAddOperatorDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenAddOperatorDialog(false);
			setReloadData((prev) => !prev);
		}
	};

	const handleUpdateSave = async () => {
		setAddLoading(true);
		const selectedGamesData = games.filter(game => selectedGames.includes(game.id));
		let selectedOperator = {
			id: selectedOperatorData.id,
			name: selectedOperatorData.name,
			location: selectedOperatorData.location,
			country_id: selectedOperatorData.country_id,
			currency_id: selectedOperatorData.currency_id,
			operator_id: selectedOperatorData.operator_id,
			bank_id: selectedOperatorData.bank_id,
			upper_limit: selectedOperatorData.upper_limit,
			bottom_limit: selectedOperatorData.bottom_limit
		}
		let data = {
			operator: selectedOperator,
			games: selectedGamesData.map(game => ({
				id: game.id,
				name: game.name,
				description: game.description,
			})),
		}
		
		let result = await apiCaller.putApiCall('operator', data);
		if (result.error) {
			setOpenUpdateOperatorDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenUpdateOperatorDialog(false);
			setReloadData((prev) => !prev);
		}
	}

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setNewOperatorData({
			name: '',
			location: '',
			country_id: '',
			currency_id: '',
			operator_id: ''
		});
		setSelectedGames([]);
		setOpenAddOperatorDialog(false);
		setSelectedOperatorData({
			id: '',
			name: '',
			location: '',
			country_id: '',
			currency_id: '',
			operator_id: ''
		});
	};

	const handleGameToggle = (gameId) => {
		setSelectedGames((prevSelectedGames) => {
			if (prevSelectedGames.includes(gameId)) {
				return prevSelectedGames.filter((id) => id !== gameId);
			} else {
				return [...prevSelectedGames, gameId];
			}
		});
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
				Operators
			</Typography>
			<Button
				disabled={loading}
				variant="contained"
				color="success"
				onClick={handleAddOperator}
				sx={{ left: '90%', marginTop: 2 }}
			>
				<AddIcon sx={{ marginRight: 1 }} />
				Add
			</Button>
			<TableContainer sx={{ maxHeight: 440 }}>
				<TextField id="outlined-basic" label="Search" variant="outlined"
					onChange={(e) => setSearchTerm(e.target.value)}
					sx={{
						width: '50%', // Make the TextField take the full width
						marginTop: '15px', // Add bottom margin
						marginLeft: '5px', // Add left margin
						marginBottom: '10px'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							// Use Skeleton for the first row (header) during loading
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id}>
										<Skeleton animation="wave" height={40} width="100%" />
									</TableCell>
								))}
							</TableRow>
						) : (
							// Render actual data when not loading
							rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
										{columns.map((column) => (
											<TableCell key={column.id} align={column.align}>
												{column.id !== 'update' && column.id !== 'delete' ? (
													column.format && typeof row[column.id] === 'number' ? (
														column.format(row[column.id])
													) : (
														row[column.id]
													)
												) : (
													<Button
														onClick={() =>
															column.id === 'update'
																? handleUpdateOperator(row.id)
																: handleDeleteOperator(row.id)
														}
														color={column.id === 'update' ? 'primary' : 'error'}
														variant="outlined"
													>
														{column.id === 'update' ? 'Update' : 'Delete'}
													</Button>
												)}
											</TableCell>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Dialog open={openAddOperatorDialog} onClose={() => setOpenAddOperatorDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
				<DialogTitle>Add New Operator</DialogTitle>
				<DialogContent>
					<Stepper activeStep={activeStep} alternativeLabel>
						<Step>
							<StepLabel>Operator Data</StepLabel>
						</Step>
						<Step>
							<StepLabel>Games</StepLabel>
						</Step>
					</Stepper>
					<br></br>
					{activeStep === 0 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Name"
									value={newOperatorData.name}
									onChange={(e) => setNewOperatorData({ ...newOperatorData, name: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Location"
									value={newOperatorData.location}
									onChange={(e) => setNewOperatorData({ ...newOperatorData, location: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="bank-label">Client</InputLabel>
									<Select
										labelId="currency-label"
										id="client"
										value={newOperatorData.bank_id}
										label="Client"
										onChange={(e) => setNewOperatorData({ ...newOperatorData, bank_id: e.target.value })}
									>
										{clients.map((client) => (
											<MenuItem key={client.id} value={client.bank_id}>
												{`${client.name}: ${client.url}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Operator Identificator"
									value={newOperatorData.operator_id}
									onChange={(e) => setNewOperatorData({ ...newOperatorData, operator_id: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="currency-label">Currency</InputLabel>
									<Select
										labelId="currency-label"
										id="currency"
										value={newOperatorData.currency_id}
										label="Currency"
										onChange={(e) => setNewOperatorData({ ...newOperatorData, currency_id: e.target.value })}
									>
										{currencies.map((currency) => (
											<MenuItem key={currency.id} value={currency.id}>
												{`${currency.name} ${currency.code} ${currency.symbol}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Bottom Limit"
									type="number"
									value={newOperatorData.bottom_limit}
									onChange={(e) => setNewOperatorData({ ...newOperatorData, bottom_limit: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Upper Limit"
									type="number"
									value={newOperatorData.upper_limit}
									onChange={(e) => setNewOperatorData({ ...newOperatorData, upper_limit: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="country-label">Country</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										value={newOperatorData.country_id}
										label="Country"
										onChange={(e) => setNewOperatorData({ ...newOperatorData, country_id: e.target.value })}
									>
										{countries.map((country) => (
											<MenuItem key={country.id} value={country.id}>
												{country.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
					{activeStep === 1 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">Select Games</Typography>
								{games.map((game) => (
									<FormControlLabel
										key={game.id}
										control={
											<Checkbox
												checked={selectedGames.includes(game.id)}
												onChange={() => handleGameToggle(game.id)}
											/>
										}
										label={game.name}
									/>
								))}
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					{activeStep === 0 ? (
						<Button onClick={() => handleReset()}>Cancel</Button>
					) : (
						<Button onClick={handleBack}>Back</Button>
					)}
					<Button onClick={activeStep === 1 ? handleSaveOperator : handleNext} color="primary">
						{activeStep === 1 ? (
							addLoading ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								'Save'
							)
						) : (
							'Next'
						)}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openUpdateOperatorDialog}
				onClose={() => setOpenUpdateOperatorDialog(false)}
				sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}
			>
				<DialogTitle>Update Operator</DialogTitle>
				<DialogContent>
					<Stepper activeStep={activeStep} alternativeLabel>
						<Step>
							<StepLabel>Operator Data</StepLabel>
						</Step>
						<Step>
							<StepLabel>Games</StepLabel>
						</Step>
					</Stepper>
					<br />
					{activeStep === 0 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Name"
									value={selectedOperatorData.name}
									onChange={(e) => setSelectedOperatorData({ ...selectedOperatorData, name: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Location"
									value={selectedOperatorData.location}
									onChange={(e) => setSelectedOperatorData({ ...selectedOperatorData, location: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="client-label">Client</InputLabel>
									<Select
										labelId="client-label"
										id="client"
										value={selectedOperatorData.bank_id || ''}
										label="Client"
										onChange={(e) => {
											setSelectedOperatorData({ ...selectedOperatorData, bank_id: e.target.value });
										}}
									>
										{clients.map((client) => (
											<MenuItem key={client.id} value={client.bank_id}>
												{`${client.name} ${client.url}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Operator Identificator"
									value={selectedOperatorData.operator_id}
									onChange={(e) => setSelectedOperatorData({ ...selectedOperatorData, operator_id: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="currency-label">Currency</InputLabel>
									<Select
										labelId="currency-label"
										id="currency"
										value={selectedOperatorData.currency_id || ''}
										label="Currency"
										onChange={(e) => {
											setSelectedOperatorData({ ...selectedOperatorData, currency_id: e.target.value });
										}}
									>
										{currencies.map((currency) => (
											<MenuItem key={currency.id} value={currency.id}>
												{`${currency.name} ${currency.code} ${currency.symbol}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Bottom Limit"
									type="number"
									value={selectedOperatorData.bottom_limit}
									onChange={(e) => setSelectedOperatorData({ ...selectedOperatorData, bottom_limit: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Upper Limit"
									type="number"
									value={selectedOperatorData.upper_limit}
									onChange={(e) => setSelectedOperatorData({ ...selectedOperatorData, upper_limit: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="country-label">Country</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										value={selectedOperatorData.country_id || ''}
										label="Country"
										onChange={(e) => {
											setSelectedOperatorData({ ...selectedOperatorData, country_id: e.target.value });
										}}
									>
										{countries.map((country) => (
											<MenuItem key={country.id} value={country.id}>
												{country.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
					{activeStep === 1 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">Select Games</Typography>
								{games.map((game) => (
									<FormControlLabel
										key={game.id}
										control={
											<Checkbox
												checked={selectedGames.includes(game.id)}
												onChange={() => handleGameToggle(game.id)}
											/>
										}
										label={game.name}
									/>
								))}
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					{activeStep === 0 ? (
						<Button onClick={() => setOpenUpdateOperatorDialog(false)}>Cancel</Button>
					) : (
						<Button onClick={handleBack}>Back</Button>
					)}
					<Button onClick={activeStep === 1 ? handleUpdateSave : handleNext} color="primary">
						{activeStep === 1 ? (
							addLoading ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								'Update'
							)
						) : (
							'Next'
						)}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={deleteConfirmationOpen}
				onClose={handleCloseDeleteConfirmation}
				sx={{ minWidth: 300 }}
			>
				<DialogTitle>Delete Operator</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this operator?
					</Typography>
					<Typography>
						Type DELETE to confirm:
					</Typography>
					<TextField
						value={deleteConfirmationText}
						onChange={(e) => setDeleteConfirmationText(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
					<Button
						onClick={() => handleConfirmDelete(operatorToDeleteId)}
						color="error"
						disabled={deleteConfirmationText !== 'DELETE'}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
}