import * as React from 'react';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Menu from './dashboardComponents/Menu';
import Header from './dashboardComponents/Header';
import Players from './dashboardComponents/players/Players';
import { useLocation, useNavigate  } from 'react-router-dom';
import Insights from './dashboardComponents/dashboard/Insights';
import Invoicing from './dashboardComponents/dashboard/Invoicing';
import ManageGames from './dashboardComponents/games/ManageGames';
import ManageTypes from './dashboardComponents/users/ManageTypes';
import ManageUsers from './dashboardComponents/users/ManageUsers';
import Statistics from './dashboardComponents/statistics/Statistics';
import ManageBonuses from './dashboardComponents/operators/ManageBonus';
import ManageEvents from './dashboardComponents/operators/ManageEvents';
import ManageClients from './dashboardComponents/clients/ManageClients';
import Summarization from './dashboardComponents/dashboard/Summarization';
import ManageOperators from './dashboardComponents/operators/ManageOperators';
import ManagePermissions from './dashboardComponents/users/ManagePermissions';
import ManageCountries from './dashboardComponents/countries/ManageCountries';
import ManageCurrencies from './dashboardComponents/currencies/ManageCurrencies';

export default function Dashboard() {

    const [manageOperatorsVisible, setManageOperatorsVisible] = React.useState(false);
    const [manageEventsVisible, setManageEventsVisible] = React.useState(false);
    const [manageBonusesVisible, setManageBonusesVisible] = React.useState(false);
    const [statisticsVisible, setStatisticsVisible] = React.useState(false);
    const [playersVisible, setPlayersVisible] = React.useState(false);
    const [manageUsersVisible, setManageUsersVisible] = React.useState(false);
    const [manageTypesVisible, setManageTypesVisible] = React.useState(false);
    const [managePermissionsVisible, setManagePermissionsVisible] = React.useState(false);
    const [manageGamesVisible, setManageGamesVisible] = React.useState(false);
    const [manageCountriesVisible, setManageCountriesVisible] = React.useState(false);
    const [manageCurrenciesVisible, setManageCurrenciesVisible] = React.useState(false);
    const [manageClientsVisible, setManageClientsVisible] = React.useState(false);
    const [insights, setInsightsVisible] = React.useState(true);
    const [invoicing, setInvoicingVisible] = React.useState(false);
    const [summaries, setSummariesVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const user = location.state ? location.state : null;
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!user) {
            navigate('/');
        } else {
            setLoading(false);
        }
    }, [user, navigate]);

    const toggleManageOperators = (toggle) => {
        setManageOperatorsVisible(toggle);
    };

    const toggleManageEvents = (toggle) => {
        setManageEventsVisible(toggle);
    };

    const toggleManageBonuses = (toggle) => {
        setManageBonusesVisible(toggle);
    };

    const toggleStatistics = (toggle) => {
        setStatisticsVisible(toggle);
    }

    const toggleManageUsers = (toggle) => {
        setManageUsersVisible(toggle);
    }

    const toggleManagePermissions = (toggle) => {
        setManagePermissionsVisible(toggle);
    }

    const toggleManageTypes = (toggle) => {
        setManageTypesVisible(toggle);
    }

    const toggleManageGames = (toggle) => {
        setManageGamesVisible(toggle);
    }

    const toggleManageCountries = (toggle) => {
        setManageCountriesVisible(toggle);
    }

    const toggleManageCurrencies = (toggle) => {
        setManageCurrenciesVisible(toggle);
    }

    const togglePlayers = (toggle) => {
        setPlayersVisible(toggle);
    }

    const toggleInsights = (toggle) => {
        setInsightsVisible(toggle);
    }

    const toggleSummaries = (toggle) => {
        setSummariesVisible(toggle);
    }

    const toggleInvoicing = (toggle) => {
        setInvoicingVisible(toggle);
    }

    const toggleClients = (toggle) => {
        setManageClientsVisible(toggle);
    }

    if (loading) {
        return null;
    }

    return (
        <div>
            <Header user={user} ></Header>
            <Menu
                onManageOperatorsClick={toggleManageOperators}
                onManageEventsClick={toggleManageEvents}
                onManageBonusesClick={toggleManageBonuses}
                onStatisticsClick={toggleStatistics}
                onManageUsersClick={toggleManageUsers}
                onManageTypesClick={toggleManageTypes}
                onManagePermissionsClick={toggleManagePermissions}
                onManageGamesClick={toggleManageGames}
                onManageCountriesClick={toggleManageCountries}
                onManageCurrenciesClick={toggleManageCurrencies}
                onPlayersClick={togglePlayers}
                onInsightsClick={toggleInsights}
                onSummariesClick={toggleSummaries}
                onInvoicingClick={toggleInvoicing}
                onClientsClick={toggleClients}
            />
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginRight: '25px', marginTop: '15px', marginLeft: '250px' }}>
                <Toolbar />
                {manageOperatorsVisible && <ManageOperators user={user} />}
                {manageEventsVisible && <ManageEvents user={user} />}
                {manageBonusesVisible && <ManageBonuses user={user} />}
                {statisticsVisible && <Statistics user={user} />}
                {manageUsersVisible && <ManageUsers user={user} />}
                {manageTypesVisible && <ManageTypes user={user} />}
                {managePermissionsVisible && <ManagePermissions user={user} />}
                {manageGamesVisible && <ManageGames user={user} />}
                {manageCountriesVisible && <ManageCountries user={user} />}
                {manageCurrenciesVisible && <ManageCurrencies user={user} />}
                {playersVisible && <Players user={user}/>}
                {insights && <Insights user={user}/>}
                {summaries && <Summarization user={user}/>}
                {invoicing && <Invoicing user={user}/>}
                {manageClientsVisible && <ManageClients user={user}/>}
            </Box>
        </div>
    );
}