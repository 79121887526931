import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'code', label: 'Code', minWidth: 150 },
    { id: 'symbol', label: 'Symbol', minWidth: 150 },
    { id: 'update', label: 'Update', minWidth: 120, align: 'center' },
    { id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name, code, symbol) {
    return { id, name, code, symbol };
}

export default function ManageCurrencies() {

    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [reloadData, setReloadData] = useState(false);
    const [rows, setRows] = useState([]);
    const [openAddCurrencyDialog, setOpenAddCurrencyDialog] = useState(false);
    const activeStep = 0;
    const [addLoading, setAddLoading] = useState(false);
    const [newCurrencyData, setNewCurrencyData] = useState({
        name: '',
        code: '',
        symbol: ''
    });
    const [selectedCurrencyData, setSelectedCurrencyData] = useState({
        id: '',
        name: '',
        code: '',
        symbol: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currencyToDeleteId, setCurrencyToDeleteId] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
    const [openUpdateCurrencyDialog, setOpenUpdateCurrencyDialog] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let route = 'currency';
            let data = await apiCaller.getApiCall(route, '');
            let currencies = [];
            for (let currency of data) {
                let newCurrencyObject = {
                    id: currency.id,
                    name: currency.name,
                    code: currency.code,
                    symbol: currency.symbol
                };
                currencies.push(newCurrencyObject);
            }
            setCurrencies(currencies);
            setLoading(false);
        };
        fetchData();
    }, [reloadData]);

    useEffect(() => {
        let filteredRows = currencies
            .filter((currency) =>
                currency.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                currency.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                currency.symbol.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((currency) =>
                createData(
                    currency.id,
                    currency.name,
                    currency.code,
                    currency.symbol
                )
            );
        setRows(filteredRows);
    }, [searchTerm, currencies]);

    const handleAddCurrency = () => {
        setOpenAddCurrencyDialog(true);
    };

    const handleSaveCurrency = async () => {
        setAddLoading(true);
        let data = {
            name: newCurrencyData.name,
            code: newCurrencyData.code,
            symbol: newCurrencyData.symbol
        };
        let result = await apiCaller.postApiCall('currency', data);
        if (result.error) {
            setOpenAddCurrencyDialog(false);
        } else {
            handleReset();
            setAddLoading(false);
            setOpenAddCurrencyDialog(false);
            setReloadData((prev) => !prev);
        }
    }

    const handleReset = () => {
        setNewCurrencyData({
            name: '',
            code: ''
        });
        setOpenAddCurrencyDialog(false);
        setSelectedCurrencyData({
            id: '',
            name: '',
            code: ''
        });
    };

    const handleUpdateCurrency = (id) => {
        const selectedCurrency = currencies.find((currency) => currency.id === id);
        setSelectedCurrencyData(selectedCurrency);
        setOpenUpdateCurrencyDialog(true);
    }

    const handleDeleteCurrency = (id) => {
        handleOpenDeleteConfirmation(id);
    }

    const handleChangePage = (currency, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (currency) => {
        setRowsPerPage(+currency.target.value);
        setPage(0);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setCurrencyToDeleteId(null);
        setDeleteConfirmationText('');
    };

    const handleOpenDeleteConfirmation = (currencyId) => {
        setDeleteConfirmationOpen(true);
        setCurrencyToDeleteId(currencyId);
        setDeleteConfirmationText('');
    };

    const handleConfirmDelete = async (id) => {
        await apiCaller.deleteApiCall('currency', id)
        handleCloseDeleteConfirmation();
        setReloadData((prev) => !prev);
    };

    const handleUpdateSave = async () => {
        setAddLoading(true);
        let data = {
            id: selectedCurrencyData.id,
            name: selectedCurrencyData.name,
            code: selectedCurrencyData.code,
            symbol: selectedCurrencyData.symbol
        }
        let result = await apiCaller.putApiCall('currency', data);
        if (result.error) {
            setOpenUpdateCurrencyDialog(false);
        } else {
            handleReset();
            setAddLoading(false);
            setOpenUpdateCurrencyDialog(false);
            setReloadData((prev) => !prev);
        }
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Currencies
            </Typography>
            <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={handleAddCurrency}
                sx={{ left: '90%', marginTop: 2 }}
            >
                <AddIcon sx={{ marginRight: 1 }} />
                Add
            </Button>

            <TableContainer sx={{ maxHeight: 440 }}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        width: '50%', // Make the TextField take the full width
                        marginTop: '15px', // Add bottom margin
                        marginLeft: '5px', // Add left margin
                        marginBottom: '10px'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            // Use Skeleton for the first row (header) during loading
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            // Render actual data when not loading
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id !== 'update' && column.id !== 'delete' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            column.id === 'update'
                                                                ? handleUpdateCurrency(row.id)
                                                                : handleDeleteCurrency(row.id)
                                                        }
                                                        color={column.id === 'update' ? 'primary' : 'error'}
                                                        variant="outlined"
                                                    >
                                                        {column.id === 'update' ? 'Update' : 'Delete'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={openAddCurrencyDialog} onClose={() => setOpenAddCurrencyDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
                <DialogTitle>Add New Currency</DialogTitle>
                <DialogContent>
                    <br></br>
                    {activeStep === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={newCurrencyData.name}
                                    onChange={(e) => setNewCurrencyData({ ...newCurrencyData, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Code"
                                    value={newCurrencyData.code}
                                    onChange={(e) => setNewCurrencyData({ ...newCurrencyData, code: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Symbol"
                                    value={newCurrencyData.symbol}
                                    onChange={(e) => setNewCurrencyData({ ...newCurrencyData, symbol: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => handleReset()}>Cancel</Button>

                    <Button onClick={handleSaveCurrency} color="primary">
                        {addLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                sx={{ minWidth: 300 }}
            >
                <DialogTitle>Delete Currency</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this currency?
                    </Typography>
                    <Typography>
                        Type DELETE to confirm:
                    </Typography>
                    <TextField
                        value={deleteConfirmationText}
                        onChange={(e) => setDeleteConfirmationText(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                    <Button
                        onClick={() => handleConfirmDelete(currencyToDeleteId)}
                        color="error"
                        disabled={deleteConfirmationText !== 'DELETE'}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUpdateCurrencyDialog}
                onClose={() => setOpenUpdateCurrencyDialog(false)}
                sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}
            >
                <DialogTitle>Update Currency</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={selectedCurrencyData ? selectedCurrencyData.name : ''}
                                onChange={(e) => setSelectedCurrencyData({ ...selectedCurrencyData, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Code"
                                value={selectedCurrencyData ? selectedCurrencyData.code : ''}
                                onChange={(e) => setSelectedCurrencyData({ ...selectedCurrencyData, code: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Symbol"
                                value={selectedCurrencyData ? selectedCurrencyData.symbol : ''}
                                onChange={(e) => setSelectedCurrencyData({ ...selectedCurrencyData, symbol: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateCurrencyDialog(false)}>Cancel</Button>
                    <Button onClick={() => handleUpdateSave(selectedCurrencyData)} color="primary">
                        {addLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Update'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}