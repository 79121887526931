import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { Checkbox } from '@mui/material';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
	{ id: 'id', label: 'ID', minWidth: 50 },
	{ id: 'name', label: 'Name', minWidth: 100 },
	{ id: 'multiplier', label: 'Multiplier', minWidth: 50 },
	{ id: 'active', label: 'Active', minWidth: 80 },
	{ id: 'operator', label: 'Operator', minWidth: 100 },
	{ id: 'update', label: 'Update', minWidth: 120, align: 'center' },
	{ id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name, multiplier, active, operator) {
	return { id, name, multiplier, active, operator };
}

export default function ManageBonuses() {

	const [bonuses, setBonuses] = useState([]);
	const [loading, setLoading] = useState(true);
	const [reloadData, setReloadData] = useState(false);
	const [rows, setRows] = useState([]);
	const [openAddBonusDialog, setOpenAddBonusDialog] = useState(false);
	const activeStep = 0;
	const [operators, setOperators] = useState([]);
	const setSelectedOperator = useState([]);
	const [addLoading, setAddLoading] = useState(false);
	const [newBonusData, setNewBonusData] = useState({
		name: '',
		multiplier: '',
		active: false,
		operator_id: ''
	});
	const [selectedBonusData, setSelectedBonusData] = useState({
		id: '',
		name: '',
		multiplier: '',
		active: '',
		operator_id: ''
	})
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [bonusToDeleteId, setBonusToDeleteId] = useState(null);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
	const [openUpdateBonusDialog, setOpenUpdateBonusDialog] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			let route = 'bonus';
			let data = await apiCaller.getApiCall(route, '');
			let bonuses = [];
			for (let bonus of data) {
				let newBonusObject = {
					id: bonus.id,
					name: bonus.name,
					multiplier: bonus.multiplier,
					active: bonus.active,
					operator_id: bonus.operator_id,
					operator: await apiCaller.getApiCall('operator', bonus.operator_id)
				};
				bonuses.push(newBonusObject);
			}
			setBonuses(bonuses);
			const operatorsData = await apiCaller.getApiCall('operator', '');
			setOperators(operatorsData);
			setLoading(false);
		};
		fetchData();
	}, [reloadData]);

	useEffect(() => {
		let filteredRows = bonuses
			.filter((bonus) =>
				bonus.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				`${bonus.multiplier}`.includes(searchTerm) ||
				bonus.operator.name.toLowerCase().includes(searchTerm.toLowerCase())
			)
			.map((bonus) =>
				createData(
					bonus.id,
					bonus.name,
					`${bonus.multiplier}`,
					bonus.active,
					bonus.operator.name
				)
			);
		setRows(filteredRows);
	}, [searchTerm, bonuses]);

	const handleChangePage = (bonus, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (bonus) => {
		setRowsPerPage(+bonus.target.value);
		setPage(0);
	};

	const handleSaveBonus = async () => {
		setAddLoading(true);
		let data = {
			name: newBonusData.name,
			multiplier: newBonusData.multiplier,
			active: newBonusData.active,
			operator_id: newBonusData.operator_id
		}
		let result = await apiCaller.postApiCall('bonus', data);
		if (result.error) {
			setOpenAddBonusDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenAddBonusDialog(false);
			setReloadData((prev) => !prev);
		}
	}

	const handleAddBonus = () => {
		setOpenAddBonusDialog(true);
	};

	const handleReset = () => {
		setNewBonusData({
			name: '',
			multiplier: '',
			active: '',
			operator_id: ''
		});
		setOpenAddBonusDialog(false);
		setSelectedBonusData({
			id: '',
			name: '',
			multiplier: '',
			active: '',
			operator_id: ''
		});
	};

	const handleUpdateBonus = async (id) => {
		const selectedBonus = bonuses.find((bonus) => bonus.id === id);
		setSelectedBonusData(selectedBonus);
		const selectedOperator = selectedBonus.operator;
		setSelectedOperator(selectedOperator);
		setOpenUpdateBonusDialog(true);
	}

	const handleDeleteBonus = async(id) => {
		handleOpenDeleteConfirmation(id)
	}

	const handleCloseDeleteConfirmation = () => {
		setDeleteConfirmationOpen(false);
		setBonusToDeleteId(null);
		setDeleteConfirmationText('');
	};

	const handleOpenDeleteConfirmation = (eventId) => {
		setDeleteConfirmationOpen(true);
		setBonusToDeleteId(eventId);
		setDeleteConfirmationText('');
	};

	const handleConfirmDelete = async (id) => {
		await apiCaller.deleteApiCall('bonus', id)
		handleCloseDeleteConfirmation();
		setReloadData((prev) => !prev);
	};

	const handleUpdateSave = async () => {
		setAddLoading(true);
		let data = {
			id: selectedBonusData.id,
			name: selectedBonusData.name,
			multiplier: selectedBonusData.multiplier,
			active: selectedBonusData.active,
			operator_id: selectedBonusData.operator_id
		}
		let result = await apiCaller.putApiCall('bonus', data);
		if (result.error) {
			setOpenUpdateBonusDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenUpdateBonusDialog(false);
			setReloadData((prev) => !prev);
		}
	}

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
				Bonuses
			</Typography>
			<Button
				disabled={loading}
				variant="contained"
				color="success"
				onClick={handleAddBonus}
				sx={{ left: '90%', marginTop: 2 }}
			>
				<AddIcon sx={{ marginRight: 1 }} />
				Add
			</Button>

			<TableContainer sx={{ maxHeight: 440 }}>
				<TextField id="outlined-basic" label="Search" variant="outlined"
					onChange={(e) => setSearchTerm(e.target.value)}
					sx={{
						width: '50%', 
						marginTop: '15px',
						marginLeft: '5px',
						marginBottom: '10px'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							// Use Skeleton for the first row (header) during loading
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id}>
										<Skeleton animation="wave" height={40} width="100%" />
									</TableCell>
								))}
							</TableRow>
						) : (
							// Render actual data when not loading
							rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
										{columns.map((column) => (
											<TableCell key={column.id} align={column.align}>
												{column.id === 'active' ? (
													<Checkbox checked={row.active} color="primary" />
												) : column.id !== 'update' && column.id !== 'delete' ? (
													column.format && typeof row[column.id] === 'number' ? (
														column.format(row[column.id])
													) : (
														row[column.id]
													)
												) : (
													<Button
														onClick={() =>
															column.id === 'update'
																? handleUpdateBonus(row.id)
																: handleDeleteBonus(row.id)
														}
														color={column.id === 'update' ? 'primary' : 'error'}
														variant="outlined"
													>
														{column.id === 'update' ? 'Update' : 'Delete'}
													</Button>
												)}
											</TableCell>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

			<Dialog open={openAddBonusDialog} onClose={() => setOpenAddBonusDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
				<DialogTitle>Add New Bonus</DialogTitle>
				<DialogContent>
					<br></br>
					{activeStep === 0 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Name"
									value={newBonusData.name}
									onChange={(e) => setNewBonusData({ ...newBonusData, name: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Multiplier"
									value={newBonusData.multiplier}
									onChange={(e) => setNewBonusData({ ...newBonusData, multiplier: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<Switch
										checked={newBonusData.active}
										onChange={() => setNewBonusData({ ...newBonusData, active: !newBonusData.active })}
										color="primary"
									/>
									<Typography>{newBonusData.active ? 'Active' : 'Inactive'}</Typography>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="country-label">Operators</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										value={newBonusData.operator_id}
										label="Operator"
										onChange={(e) => setNewBonusData({ ...newBonusData, operator_id: e.target.value })}
									>
										{operators.map((operator) => (
											<MenuItem key={operator.id} value={operator.id}>
												{operator.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleReset()}>Cancel</Button>
					<Button onClick={handleSaveBonus} color="primary">
						{addLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							'Save'
						)}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={deleteConfirmationOpen}
				onClose={handleCloseDeleteConfirmation}
				sx={{ minWidth: 300 }}
			>
				<DialogTitle>Delete Bonus</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this bonus?
					</Typography>
					<Typography>
						Type DELETE to confirm:
					</Typography>
					<TextField
						value={deleteConfirmationText}
						onChange={(e) => setDeleteConfirmationText(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
					<Button
						onClick={() => handleConfirmDelete(bonusToDeleteId)}
						color="error"
						disabled={deleteConfirmationText !== 'DELETE'}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openUpdateBonusDialog}
				onClose={() => setOpenUpdateBonusDialog(false)}
				sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}
			>
				<DialogTitle>Update Bonus</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Name"
								value={selectedBonusData ? selectedBonusData.name : ''}
								onChange={(e) => setSelectedBonusData({ ...selectedBonusData, name: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Multiplier"
								value={selectedBonusData ? selectedBonusData.multiplier : ''}
								onChange={(e) => setSelectedBonusData({ ...selectedBonusData, multiplier: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="operator-label">Operators</InputLabel>
								<Select
									labelId="operator-label"
									id="operator"
									value={selectedBonusData ? selectedBonusData.operator_id : ''}
									label="Operator"
									onChange={(e) => setSelectedBonusData({ ...selectedBonusData, operator_id: e.target.value })}
								>
									{operators.map((operator) => (
										<MenuItem key={operator.id} value={operator.id}>
											{operator.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenUpdateBonusDialog(false)}>Cancel</Button>
					<Button onClick={() => handleUpdateSave(selectedBonusData)} color="primary">
						{addLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							'Update'
						)}
					</Button>
				</DialogActions>
			</Dialog>

		</Paper>
	)

}