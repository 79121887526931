import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Chart, registerables } from 'chart.js';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import * as apiCaller from '../../../util/ApiCaller';
import CircularProgress from '@mui/material/CircularProgress';

Chart.register(...registerables);

export default function Insights() {

    const [loading, setLoading] = useState(false);
    const [statsData, setStatsData] = useState([]);
    const [totalPlayersByGame, setTotalPlayersByGame] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const fetchedStats = await apiCaller.getApiCall('insigths/stats', '');
            setStatsData(fetchedStats.statsData);
            setTotalPlayersByGame(fetchedStats.totalPlayersByGame);
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (statsData.length > 0 && totalPlayersByGame.length > 0) {
            // Chart for Players Over Time
            const playersOverTime = statsData.map((item) => item.playersOverTime);
            const months = statsData.map((item) => item.month);
            const earningsOverTime = statsData.map((item) => item.earningsOverTime);
            const roundsOverTime = statsData.map((item) => item.roundsOverTime);

            const ctx1 = document.getElementById('playersOverTimeChart');
            new Chart(ctx1, {
                type: 'bar',
                data: {
                    labels: months,
                    datasets: [{
                        label: 'Players Over Time',
                        data: playersOverTime,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Players Over Time'
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Months'
                            }
                        }
                    }
                }
            });

            // Chart for Earnings Over Time
            const ctx2 = document.getElementById('earningsOverTimeChart');
            new Chart(ctx2, {
                type: 'line',
                data: {
                    labels: months,
                    datasets: [{
                        label: 'Earnings Over Time',
                        data: earningsOverTime,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Earnings Over Time'
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Months'
                            }
                        }
                    }
                }
            });

            // Chart for Total Players by Game (Pie Chart)
            const totalPlayersData = {
                labels: totalPlayersByGame.map((game) => game.game_name),
                datasets: [{
                    label: 'Total Players by Game',
                    data: totalPlayersByGame.map((game) => game.totalPlayers),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.6)',
                        'rgba(54, 162, 235, 0.6)',
                        'rgba(255, 206, 86, 0.6)',
                        'rgba(75, 192, 192, 0.6)',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }]
            };

            const ctx3 = document.getElementById('totalPlayersChart');
            new Chart(ctx3, {
                type: 'pie',
                data: totalPlayersData,
                options: {
                    plugins: {
                        legend: {
                            position: 'right',
                        },
                    },
                },
            });

            // Chart for Rounds Over Time
            const ctx4 = document.getElementById('roundsOverTimeChart');
            new Chart(ctx4, {
                type: 'line',
                data: {
                    labels: months,
                    datasets: [{
                        label: 'Rounds Over Time',
                        data: roundsOverTime,
                        fill: false,
                        borderColor: 'rgb(255, 99, 132)',
                        tension: 0.1,
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Rounds Over Time'
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Months'
                            }
                        }
                    }
                }
            });
        }
    }, [statsData, totalPlayersByGame]);

    return (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2, marginTop: 20 }}>
            {loading ? (
                <CircularProgress size={360} />
            ) : (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography align='center' variant='h5'>Players Over Time</Typography>
                                <canvas id="playersOverTimeChart" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography align='center' variant='h5'>Earnings Over Time</Typography>
                                <canvas id="earningsOverTimeChart" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography align='center' variant='h5'>Total Players by Game</Typography>
                                <canvas width="250px" height="250px" id="totalPlayersChart" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography align='center' variant='h5'>Rounds Over Time</Typography>
                                <canvas id="roundsOverTimeChart" />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}