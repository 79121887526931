import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import PaidIcon from '@mui/icons-material/Paid';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import CasinoIcon from '@mui/icons-material/Casino';
import CssBaseline from '@mui/material/CssBaseline';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssessmentIcon from '@mui/icons-material/Speed';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import InsightsIcon from '@mui/icons-material/Insights';
import ListItemButton from '@mui/material/ListItemButton';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;

const menuItems = [
	{
		key: 'dashboard',
		icon: <AssessmentIcon />,
		text: 'Dashboard',
		index: 7,
		subMenu: [
			{ key: 'invoice', icon: <ReceiptIcon />, text: 'Invoices' },
			{ key: 'summary', icon: <MultilineChartIcon />, text: 'Summaries' }, // will show earnings per game and players, and players per game, how many rounds are played per game on a monthy basis, basic charts and activity reports
			{ key: 'stats', icon: <InsightsIcon />, text: 'Insights' }, // shows a general statistics for the whole operation. Amount earned so far, since the beginning of the operation and can be filtered monthly. 
		],
	},
	{
		key: 'operators',
		icon: <CasinoIcon />,
		text: 'Operators',
		index: 0,
		subMenu: [
			{ key: 'opmanage', icon: <SettingsIcon />, text: 'Manage' },
			{ key: 'events', icon: <EventIcon />, text: 'Events' },
			{ key: 'bonuses', icon: <MonetizationOnIcon />, text: 'Bonuses' },
		],
	},
	{
		key: 'users',
		icon: <PersonIcon />,
		text: 'Users',
		index: 1,
		subMenu: [
			{ key: 'usermanage', icon: <SettingsIcon />, text: 'Manage' },
			{ key: 'types', icon: <AdminPanelSettingsIcon />, text: 'Types' },
			{ key: 'permissions', icon: <SecurityIcon />, text: 'Permissions' },
		],
	},
	{
		key: 'games',
		icon: <SportsEsportsIcon />,
		text: 'Games',
		index: 2,
		subMenu: [
		],
	},
	{
		key: 'players',
		icon: <GroupIcon />,
		text: 'Players',
		index: 3,
		subMenu: [
		],
	},
	{
		key: 'statistics',
		icon: <BarChartIcon />,
		text: 'Statistics',
		index: 4,
		subMenu: [
		],
	},
	{
		key: 'countries',
		icon: <PlaceIcon />,
		text: 'Countries',
		index: 5,
		subMenu: [
		],
	},
	{
		key: 'currencies',
		icon: <PaidIcon />,
		text: 'Currencies',
		index: 6,
		subMenu: [
		],
	},
	{
		key: 'clients',
		icon: <AccountBalanceIcon />,
		text: 'Clients',
		index: 7,
		subMenu: [
		],
	}
];

export default function Menu(props) {

	const [openSubMenu, setOpenSubMenu] = useState(null);
	const [selectedIndex, setIndex] = useState();

	const optionClick = (itemName, id) => {
		setIndex(id);
		switch (itemName) {
			case "operators":
				handleSubMenuClick(itemName);
				break;
			case "users":
				handleSubMenuClick(itemName);
				break;
			case "games":
				handleSubMenuClick(null);
				props.onStatisticsClick(false);
				props.onManageOperatorsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(true);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "players":
				handleSubMenuClick(null);
				props.onStatisticsClick(false);
				props.onManageOperatorsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(true);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "statistics":
				handleSubMenuClick(null);
				props.onStatisticsClick(true);
				props.onManageOperatorsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "countries":
				handleSubMenuClick(null);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(true);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "currencies":
				handleSubMenuClick(null);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(true);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "opmanage":
				handleSubMenuClick(null);
				props.onManageOperatorsClick(true);
				props.onStatisticsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "events":
				handleSubMenuClick(null);
				props.onManageEventsClick(true)
				props.onStatisticsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(false);
				props.onManageOperatorsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "bonuses":
				handleSubMenuClick(null);
				props.onManageEventsClick(false)
				props.onStatisticsClick(false);
				props.onManageUsersClick(false);
				props.onManageBonusesClick(true);
				props.onManageOperatorsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "usermanage":
				handleSubMenuClick(null);
				props.onManageUsersClick(true);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "types":
				handleSubMenuClick(null);
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(true);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "permissions":
				handleSubMenuClick(null);
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(true);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "dashboard":
				handleSubMenuClick(itemName);
				break;
			case "summary":
				handleSubMenuClick(null);
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(true);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "invoice":
				handleSubMenuClick(null);
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(true);
				props.onClientsClick(false);
				break;
			case "stats":
				handleSubMenuClick(null);
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(true);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(false);
				break;
			case "clients":
				props.onManageUsersClick(false);
				props.onManageOperatorsClick(false);
				props.onStatisticsClick(false);
				props.onManageBonusesClick(false);
				props.onManageEventsClick(false);
				props.onManageTypesClick(false);
				props.onManagePermissionsClick(false);
				props.onManageGamesClick(false);
				props.onManageCurrenciesClick(false);
				props.onManageCountriesClick(false);
				props.onPlayersClick(false);
				props.onInsightsClick(false);
				props.onSummariesClick(false);
				props.onInvoicingClick(false);
				props.onClientsClick(true);
				break;
			default:
				break;
		}

	};

	const handleSubMenuClick = (menuItem) => {
		setOpenSubMenu(menuItem === openSubMenu ? null : menuItem);
	};

	const getListItemStyle = (key, id) => {
		return {
			marginTop: openSubMenu ? (key === openSubMenu ? '0' : '160px') : '0',
		};
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Drawer
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', overflowX: 'hidden' },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: 'hidden' }}>
					<List>
						{menuItems.map((item, index) => (
							<div key={item.key}>
								<ListItem disablePadding sx={selectedIndex + 1 === index ? getListItemStyle(item.key, index) : '0'}>
									<ListItemButton onClick={() => optionClick(item.key, index)}>
										<ListItemIcon>{item.icon}</ListItemIcon>
										<ListItemText primary={item.text} />
									</ListItemButton>
									{openSubMenu === item.key && (
										<List
											sx={{
												position: 'absolute',
												top: '48px',
												left: '0',
												background: 'white',
												zIndex: 1,
												width: '100%',
												marginLeft: '25px',
											}}
										>
											{item.subMenu.map((subItem) => (
												<ListItem key={subItem.key} disablePadding>
													<ListItemButton onClick={() => optionClick(subItem.key, index)}>
														<ListItemIcon>{subItem.icon}</ListItemIcon>
														<ListItemText primary={subItem.text} />
													</ListItemButton>
												</ListItem>
											))}
										</List>
									)}
								</ListItem>
								{item.key === 'statistics' && <Divider />}
								{item.key === 'dashboard' && <Divider />}
							</div>
						))}
					</List>
				</Box>
			</Drawer>
		</Box>
	);
}
