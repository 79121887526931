import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import logo from '../img/FunCrashersLogo.png';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import * as apiCaller from '../util/ApiCaller';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                Funcrashers.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {

    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [display, setDisplay] = useState('none');
    const [showPassword, setShowPassword] = useState(false);
    const [loaderDisplay, setLoaderDisplay] = useState('none');
    const [buttonDisplay, setButtonDisplay] = useState('block');
    const [messageDisplay, setMessageDisplay] = useState('none');
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoaderDisplay('flex');
        setButtonDisplay('none');
        const data = new FormData(event.currentTarget);
        if (!data.get('email').trim() || !data.get('password').trim()) {
            setLoaderDisplay('none');
            setMessageDisplay('block');
            setButtonDisplay('block');
            await delay(3000);
            setMessageDisplay('none');
        } else {
            let loginData = {
                username: data.get('email'),
                password: data.get('password')
            };
            let route = 'user/login';
            let res = await apiCaller.postApiCall(route, loginData);
            if (res.status !== "error") {
                let userPermissions = await apiCaller.getApiCall('user/permission', res.id);
                let uType = await apiCaller.getApiCall('type', res.type_id);
                let operators = await apiCaller.getApiCall('user/operator', res.id);
                navigate('/dashboard', {
                    state: {
                        user: res,
                        permissions: userPermissions,
                        type: uType,
                        operators: operators
                    }
                });
            } else {
                setMessage(res.message);
                setDisplay('block');
                handleDissapear();
                setLoaderDisplay('none');
                setButtonDisplay('block');
            }
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleDissapear = async () => {
        await delay(3000);
        setDisplay('none');
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    height: '70px',
                    width: '75%',
                    margin: 'auto',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginTop: '80px'
                }}
            />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            ></Box>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: '#21bbfe' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={handleTogglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Typography sx={{ color: 'red', display: display }}>
                        {message}
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ display: buttonDisplay, mt: 3, mb: 2, backgroundColor: '#21bbfe' }}
                    >
                        Sign In
                    </Button>
                    <Box sx={{ display: loaderDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <CircularProgress sx={{ color: '#21bbfe' }} />
                    </Box>
                    <Box sx={{ display: messageDisplay, justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                        <Typography component="p" sx={{ color: 'red' }}>* All fields are required</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/forgotpassword" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/askaccess" variant="body2">
                                {"Don't have an account? Ask for access"}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item style={{ textAlign: 'center', margin: '0 auto' }}>
                            <Link href="/statisticslookup" variant="body2">
                                {"Search for round results"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}