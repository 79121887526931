import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'bets', label: 'Total Amount Bet', minWidth: 100 },
    { id: 'wins', label: 'Total Won Rounds', minWidth: 80 },
    { id: 'loses', label: 'Total Lost Rounds', minWidth: 80 },
    { id: 'played', label: 'Total Played Rounds', minWidth: 80 },
    { id: 'details', label: 'Look Up', minWidth: 100, align: 'center' }
];

function createData(id, bets, wins, loses, played) {
    return { id, bets, wins, loses, played };
}

export default function Players() {

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const rowsPerPage = 50;
    const [rows, setRows] = useState([]);
    const [players, setPlayersData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchedPagesRef = useRef([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (fetchedPagesRef.current.includes(actualPage)) {
                setLoading(false);
            } else {
                let route = `player/page/${actualPage}`;
                let data = await apiCaller.getApiCall(route, '');
                let players = []
                for (let player of data.players) {
                    let newPlayerObject = {
                        id: player.id,
                        wallet: player.wallet,
                        earnings: player.earnings,
                        bet: player.bet,
                        wins: player.wins,
                        loses: player.loses,
                        loss: player.loss,
                        currency: await apiCaller.getApiCall('currency', player.currency_id),
                        operator_user_id: player.operator_user_id
                    };
                    players.push(newPlayerObject);
                }
                setPlayersData(prevPlayers => [...prevPlayers, ...players]);
                setTotalRecords(data.total);
                setLoading(false);
                fetchedPagesRef.current = [...fetchedPagesRef.current, actualPage];
            }
        };
        fetchData();
    }, [actualPage]);

    useEffect(() => {
        let filteredRows = players
            .filter((player =>
                player.bet.toString().includes(searchTerm) ||
                player.wins.toString().includes(searchTerm) ||
                player.loses.toString().includes(searchTerm) ||
                (player.loses + player.wins).toString().includes(searchTerm)

            ))
            .map((player) =>
                createData(
                    player.id,
                    player.bet,
                    player.wins,
                    player.loses,
                    (player.wins + player.loses)
                )
            );

        setRows(filteredRows);
    }, [searchTerm, players]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActualPage(newPage + 1);
    };

    const handleLookUpPlayers = (id) => {
        let dataSelected = players.find(player => player.id === id);
        setSelectedRow({ data: dataSelected });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Players
            </Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        width: '50%', // Make the TextField take the full width
                        marginTop: '70px', // Add bottom margin
                        marginLeft: '5px', // Add left margin
                        marginBottom: '10px'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            // Use Skeleton for the first row (header) during loading
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            // Render actual data when not loading
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id !== 'details' && column.id !== 'delete' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() => handleLookUpPlayers(row.id)}
                                                        color={'primary'}
                                                        variant="outlined"
                                                    >
                                                        {'Look Up'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={100}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count} (${Math.ceil(count / rowsPerPage)} pages)`;
                }}
            />
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Player Details</DialogTitle>
                <DialogContent>
                    {/* Render additional insights and stats using the selectedRow data */}
                    {selectedRow && (
                        <Grid container spacing={1}>
                            {/* General Information Column */}
                            <Grid item xs={5}>
                                <Typography component="h1" variant="h6">Detailed Player Financies </Typography>
                                <p>ID: {selectedRow.data.id}</p>
                                <p>Operator Player ID: {selectedRow.data.operator_user_id}</p>
                                <p>Approximate Wallet: {selectedRow.data.wallet}</p>
                                <p>Total Earnings: {selectedRow.data.earnings}</p>
                                <p>Total Loses: {selectedRow.data.loss}</p>
                                <p>Total Bet: {selectedRow.data.bet}</p>
                                <p>Preferred Currency: {selectedRow.data.currency.name}</p>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>

    );
}