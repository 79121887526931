import dayjs from 'dayjs';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { Checkbox } from '@mui/material';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const columns = [
	{ id: 'id', label: 'ID', minWidth: 50 },
	{ id: 'name', label: 'Name', minWidth: 100 },
	{ id: 'budget', label: 'Budget', minWidth: 50 },
	{ id: 'start', label: 'Start Date', minWidth: 100 },
	{ id: 'end', label: 'End Date', minWidth: 100 },
	{ id: 'active', label: 'Active', minWidth: 80 },
	{ id: 'operator', label: 'Operator', minWidth: 100 },
	{ id: 'update', label: 'Update', minWidth: 120, align: 'center' },
	{ id: 'delete', label: 'Delete', minWidth: 120, align: 'center' },
];

function createData(id, name, budget, active, start, end, operator) {
	return { id, name, budget, start, end, operator };
}

function isActive(startDateString, endDateString) {
	const currentDate = new Date();
	const startDate = new Date(startDateString);
	const endDate = new Date(endDateString);
	return currentDate >= startDate && currentDate <= endDate;
}

export default function ManageEvents() {

	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const [reloadData, setReloadData] = useState(false);
	const [rows, setRows] = useState([]);
	const [openAddEventDialog, setOpenAddEventDialog] = useState(false);
	const activeStep = 0;
	const [operators, setOperators] = useState([]);
	const setSelectedOperator = useState([]);
	const [addLoading, setAddLoading] = useState(false);
	const [newEventData, setNewEventData] = useState({
		name: '',
		budget: '',
		active: '',
		start: '',
		end: '',
		operator_id: ''
	});
	const [selectedEventData, setSelectedEventData] = useState({
		id: '',
		name: '',
		budget: '',
		active: '',
		start: '',
		end: '',
		operator_id: ''
	});
	const [startDate, setStartDate] = useState(null);
	const [updateStartDate, setUpdateStartDate] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [eventToDeleteId, setEventToDeleteId] = useState(null);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
	const [openUpdateEventDialog, setOpenUpdateEventDialog] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			let route = 'event';
			let data = await apiCaller.getApiCall(route, '');
			let events = [];
			for (let event of data) {
				let newEventObject = {
					id: event.id,
					name: event.name,
					budget: event.budget,
					active: event.active,
					start: event.start,
					end: event.end,
					operator_id: event.operator_id,
					operator: await apiCaller.getApiCall('operator', event.operator_id)
				};
				events.push(newEventObject);
			}
			setEvents(events);
			const operatorsData = await apiCaller.getApiCall('operator', '');
			setOperators(operatorsData);
			setLoading(false);
		};
		fetchData();
	}, [reloadData]);

	useEffect(() => {
		let filteredRows = events
			.filter((event) =>
				event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				`${event.budget}`.includes(searchTerm) ||
				event.operator.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				`${event.start.split('T')[0]} ${event.end.split('T')[0]}`.includes(searchTerm.toLowerCase())
			)
			.map((event) =>
				createData(
					event.id,
					event.name,
					`${event.budget}`,
					event.active,
					event.start.split('T')[0],
					event.end.split('T')[0],
					event.operator.name
				)
			);
		setRows(filteredRows);
	}, [searchTerm, events]);

	const handleAddEvent = () => {
		setOpenAddEventDialog(true);
	};

	const handleSaveEvent = async () => {
		setAddLoading(true);
		const startDateTime = startDate ? new Date(startDate) : null;
		if (startDateTime) {
			startDateTime.setUTCHours(0, 0, 0, 0);
		}

		const endDateTime = newEventData.end ? new Date(newEventData.end) : null;
		if (endDateTime) {
			endDateTime.setUTCHours(23, 59, 59, 999);
		}
		const formattedStartDate = startDateTime ? startDateTime.toISOString().slice(0, 23).replace('T', ' ') : null;
		const formattedEndDate = endDateTime ? endDateTime.toISOString().slice(0, 23).replace('T', ' ') : null;
		let data = {
			name: newEventData.name,
			budget: newEventData.budget,
			active: true,
			start: formattedStartDate,
			end: formattedEndDate,
			operator_id: newEventData.operator_id
		}
		let result = await apiCaller.postApiCall('event', data);
		if (result.error) {
			setOpenAddEventDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenAddEventDialog(false);
			setReloadData((prev) => !prev);
		}
	}

	const handleReset = () => {
		setNewEventData({
			name: '',
			budget: '',
			active: '',
			start: '',
			end: '',
			operator_id: ''
		});
		setOpenAddEventDialog(false);
		setSelectedEventData({
			id: '',
			name: '',
			budget: '',
			active: '',
			start: '',
			end: '',
			operator_id: ''
		});
	};

	const handleStartDateChange = (date) => {
		setNewEventData({ ...newEventData, start: date });
		setStartDate(date);
	};

	const handleStartDateChangeOnUpdate = (date) => {
		setSelectedEventData({ ...selectedEventData, start: date });
		setUpdateStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setNewEventData({ ...newEventData, end: date });
	};

	const handleEndDateChangeOnUpdate = (date) => {
		setSelectedEventData({ ...selectedEventData, end: date });
	};

	const handleUpdateEvent = (id) => {
		const selectedEvent = events.find((event) => event.id === id);
		setSelectedEventData(selectedEvent);
		const selectedOperator = selectedEvent.operator;
		setSelectedOperator(selectedOperator);
		setOpenUpdateEventDialog(true);
	}

	const handleDeleteEvent = (id) => {
		handleOpenDeleteConfirmation(id);
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleCloseDeleteConfirmation = () => {
		setDeleteConfirmationOpen(false);
		setEventToDeleteId(null);
		setDeleteConfirmationText('');
	};

	const handleOpenDeleteConfirmation = (eventId) => {
		setDeleteConfirmationOpen(true);
		setEventToDeleteId(eventId);
		setDeleteConfirmationText('');
	};

	const handleConfirmDelete = async (id) => {
		await apiCaller.deleteApiCall('event', id)
		handleCloseDeleteConfirmation();
		setReloadData((prev) => !prev);
	};

	const handleUpdateSave = async () => {
		setAddLoading(true);
		const startDateTime = updateStartDate ? new Date(updateStartDate) : new Date(selectedEventData.start);
		if (startDateTime) {
			startDateTime.setUTCHours(0, 0, 0, 0);
		}

		const endDateTime = selectedEventData.end ? new Date(selectedEventData.end) : selectedEventData.end;
		if (endDateTime) {
			endDateTime.setUTCHours(23, 59, 59, 999);
		}
		const formattedStartDate = startDateTime ? startDateTime.toISOString().slice(0, 23).replace('T', ' ') : null;
		const formattedEndDate = endDateTime ? endDateTime.toISOString().slice(0, 23).replace('T', ' ') : null;
		let data = {
			id: selectedEventData.id,
			name: selectedEventData.name,
			budget: selectedEventData.budget,
			active: true,
			start: formattedStartDate,
			end: formattedEndDate,
			operator_id: selectedEventData.operator_id
		}
		let result = await apiCaller.putApiCall('event', data);
		if (result.error) {
			setOpenUpdateEventDialog(false);
		} else {
			handleReset();
			setAddLoading(false);
			setOpenUpdateEventDialog(false);
			setReloadData((prev) => !prev);
		}
	}

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
				Events
			</Typography>

			<Button
				disabled={loading}
				variant="contained"
				color="success"
				onClick={handleAddEvent}
				sx={{ left: '90%', marginTop: 2 }}
			>
				<AddIcon sx={{ marginRight: 1 }} />
				Add
			</Button>

			<TableContainer sx={{ maxHeight: 440 }}>
				<TextField id="outlined-basic" label="Search" variant="outlined"
					onChange={(e) => setSearchTerm(e.target.value)}
					sx={{
						width: '50%', // Make the TextField take the full width
						marginTop: '15px', // Add bottom margin
						marginLeft: '5px', // Add left margin
						marginBottom: '10px'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							// Use Skeleton for the first row (header) during loading
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id}>
										<Skeleton animation="wave" height={40} width="100%" />
									</TableCell>
								))}
							</TableRow>
						) : (
							// Render actual data when not loading
							rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
										{columns.map((column) => (
											<TableCell key={column.id} align={column.align}>
												{column.id === 'active' ? (
													isActive(row.start, row.end) ? (
														<Checkbox checked={true} color="primary" />
													) : (
														<Checkbox checked={false} color="error" />
													)
												) : column.id !== 'update' && column.id !== 'delete' ? (
													column.format && typeof row[column.id] === 'number' ? (
														column.format(row[column.id])
													) : (
														row[column.id]
													)
												) : (
													<Button
														onClick={() =>
															column.id === 'update'
																? handleUpdateEvent(row.id)
																: handleDeleteEvent(row.id)
														}
														color={column.id === 'update' ? 'primary' : 'error'}
														variant="outlined"
													>
														{column.id === 'update' ? 'Update' : 'Delete'}
													</Button>
												)}
											</TableCell>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

			<Dialog open={openAddEventDialog} onClose={() => setOpenAddEventDialog(false)} sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}>
				<DialogTitle>Add New Event</DialogTitle>
				<DialogContent>
					<br></br>
					{activeStep === 0 && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Name"
									value={newEventData.name}
									onChange={(e) => setNewEventData({ ...newEventData, name: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Budget"
									value={newEventData.budget}
									onChange={(e) => setNewEventData({ ...newEventData, budget: e.target.value })}
								/>
							</Grid>
							<Grid item xs={5}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker value={startDate}
											onChange={handleStartDateChange} label="Start Date" fullWidth />
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={2}>
								<Typography align="center" variant="h6" sx={{ marginTop: '15px' }}>
									-
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker onChange={handleEndDateChange} minDate={startDate} disabled={!startDate} value={null} label="End Date" fullWidth />
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel id="country-label">Operators</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										value={newEventData.operator_id}
										label="Operator"
										onChange={(e) => setNewEventData({ ...newEventData, operator_id: e.target.value })}
									>
										{operators.map((operator) => (
											<MenuItem key={operator.id} value={operator.id}>
												{operator.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>

					<Button onClick={() => handleReset()}>Cancel</Button>

					<Button onClick={handleSaveEvent} color="primary">
						{addLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							'Save'
						)}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={deleteConfirmationOpen}
				onClose={handleCloseDeleteConfirmation}
				sx={{ minWidth: 300 }}
			>
				<DialogTitle>Delete Event</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this event?
					</Typography>
					<Typography>
						Type DELETE to confirm:
					</Typography>
					<TextField
						value={deleteConfirmationText}
						onChange={(e) => setDeleteConfirmationText(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
					<Button
						onClick={() => handleConfirmDelete(eventToDeleteId)}
						color="error"
						disabled={deleteConfirmationText !== 'DELETE'}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openUpdateEventDialog}
				onClose={() => setOpenUpdateEventDialog(false)}
				sx={{ minWidth: 700, maxWidth: 700, margin: 'auto' }}
			>
				<DialogTitle>Update Event</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Name"
								value={selectedEventData ? selectedEventData.name : ''}
								onChange={(e) => setSelectedEventData({ ...selectedEventData, name: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Budget"
								value={selectedEventData ? selectedEventData.budget : ''}
								onChange={(e) => setSelectedEventData({ ...selectedEventData, budget: e.target.value })}
							/>
						</Grid>
						<Grid item xs={5}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker']}>
									<DatePicker value={selectedEventData ? dayjs(selectedEventData.start) : ''}
										onChange={handleStartDateChangeOnUpdate} label="Start Date" fullWidth />
								</DemoContainer>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={2}>
							<Typography align="center" variant="h6" sx={{ marginTop: '15px' }}>
								-
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker']}>
									<DatePicker value={selectedEventData ? dayjs(selectedEventData.end) : ''} minDate={dayjs(selectedEventData.start)} onChange={handleEndDateChangeOnUpdate} label="End Date" fullWidth />
								</DemoContainer>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="country-label">Operators</InputLabel>
								<Select
									labelId="country-label"
									id="country"
									value={selectedEventData ? selectedEventData.operator_id : ''}
									label="Operator"
									onChange={(e) => setSelectedEventData({ ...selectedEventData, operator_id: e.target.value })}
								>
									{operators.map((operator) => (
										<MenuItem key={operator.id} value={operator.id}>
											{operator.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenUpdateEventDialog(false)}>Cancel</Button>
					<Button onClick={() => handleUpdateSave(selectedEventData)} color="primary">
						{addLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							'Update'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</Paper >
	)
}