import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';

export default function Header() {

    const location = useLocation();
    const user = location.state ? location.state : null;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        setAnchorEl(null);
        navigate('/');
    }

    const goToProfile = () => {
        setAnchorEl(null);
        navigate('/profile', {
            state: user
        });
    }

    const goToDashboard = () => {
        setAnchorEl(null);
        navigate('/dashboard', {
            state: user
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            onClick={goToDashboard}
                            variant="h6"
                            component="div"
                            sx={{
                                cursor: 'pointer',
                                width: '300px', // Fixed width for Typography
                                '&:hover': {
                                    color: 'rgba(255, 255, 255, 0.7)', // Change color on hover
                                },
                                '&:focus': {
                                    color: 'rgba(255, 255, 255, 0.7)', // Change color on focus
                                    outline: 'none', // Remove default focus outline
                                },
                            }}
                        >
                            FUNCRASHERS DASHBOARD
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={goToProfile}>Profile</MenuItem>
                            <MenuItem onClick={logOut}>Log Out</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}