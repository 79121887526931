import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import * as apiCaller from '../../../util/ApiCaller';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import React, { useState, useEffect, useRef } from 'react';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'date', label: 'Timestamp', minWidth: 100 },
    { id: 'period', label: 'Period', minWidth: 80 },
    { id: 'operator_name', label: 'Operator', minWidth: 80 },
    { id: 'bank_id', label: 'Bank ID', minWidth: 80 },
    { id: 'total_processed_transactions', label: 'Transactions', minWidth: 80 },
    { id: 'total_charge', label: 'Amount', minWidth: 80 },
    { id: 'details', label: 'Look Up', minWidth: 100 },
    { id: 'delete', label: 'Delete', minWidth: 120 }
];

function createData(id, date, period, operator_name, total_processed_transactions, total_charge, bank_id) {
    return { id, date, period, operator_name, bank_id, total_processed_transactions, total_charge };
}

export default function Invoicing() {

    const [loading, setLoading] = useState(true);
    const [actualPage, setActualPage] = useState(1);
    const [page, setPage] = useState(0);
    const rowsPerPage = 50;
    const [rows, setRows] = useState([]);
    const [invoices, setInvoicesData] = useState([]);
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const fetchedPagesRef = useRef([]);
    const [reloadData, setReloadData] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
    const [invoiceToDeleteId, setInvoiceToDeleteId] = useState(null);
    const [openGenerateInvoice, setOpenGenerateInvoiceDialog] = useState(false);
    const [operators, setOperators] = useState([]);
    const [years, setYears] = useState([]);
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
    const [newInvoiceData, setNewInvoiceData] = useState({
        month: '',
        year: '',
        operator_name: '',
        operator_id: '',
        bank_id: ''
    });
    const [addLoading, setAddLoading] = useState(false);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (fetchedPagesRef.current.includes(actualPage)) {
                setLoading(false);
            } else {
                setInvoicesData([]);
                let route = `invoice/page/${actualPage}`;
                let data = await apiCaller.getApiCall(route, '');
                let invoices = []
                for (let invoice of data.total.invoices) {
                    let newInvoiceObject = {
                        id: invoice.id,
                        create_date: invoice.create_date,
                        period: invoice.period,
                        operator_name: invoice.operator_name,
                        operator_id: invoice.operator_id,
                        total_bet: invoice.total_bet,
                        player_winnings: invoice.player_winnings,
                        total_charge: invoice.total_charge,
                        total_processed_transactions: invoice.total_processed_transactions,
                        bank_id: invoice.bank_id
                    };
                    invoices.push(newInvoiceObject);
                }
                let operatorsList = await apiCaller.getApiCall('operator', '');
                let clientsData = await apiCaller.getApiCall('client', '');
                setClients(clientsData);
                setOperators(operatorsList);
                setInvoicesData(prevInvoices => [...prevInvoices, ...invoices]);
                setTotalRecords(data.total);
                const currentYear = new Date().getFullYear();
                const yearsToAdd = [];
                for (let year = currentYear - 5; year <= currentYear + 5; year++) {
                    yearsToAdd.push(year);
                }
                setYears(yearsToAdd);
                fetchedPagesRef.current = [...fetchedPagesRef.current, actualPage];
                setLoading(false);
            }
        };
        fetchData();
    }, [actualPage, reloadData]);

    useEffect(() => {
        let filteredRows = invoices
            .filter(
                (invoice) =>
                    invoice.period.includes(searchTerm.toLowerCase()) ||
                    invoice.operator_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((invoice) =>
                createData(
                    invoice.id,
                    invoice.create_date,
                    invoice.period,
                    invoice.operator_name,
                    invoice.total_processed_transactions,
                    invoice.total_charge,
                    invoice.bank_id
                ));

        setRows(filteredRows);
    }, [searchTerm, invoices]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActualPage(newPage + 1);
    };

    const handleGenerateInvoice = async () => {
        setOpenGenerateInvoiceDialog(true);
    }

    const handleCloseDetail = async () => {
        setOpenDetail(false);
    }

    const downloadInvoice = async () => {
        const pdf = new jsPDF();
        const element = document.getElementById('invoice-detail');

        if (!element) return; // No element found to generate invoice

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 10, 10);
            pdf.save(`invoice_${selectedRow.data.id}.pdf`);
        });
    }

    const handleLookUp = (id) => {
        let dataSelected = invoices.find(invoice => invoice.id === id);
        setSelectedRow({ data: dataSelected });
        setOpenDetail(true);
    };

    const handleDeleteInvoice = (id) => {
        handleOpenDeleteConfirmation(id);
    };

    const handleConfirmDelete = async (id) => {
        await apiCaller.deleteApiCall('invoice', id)
        handleCloseDeleteConfirmation();
        fetchedPagesRef.current = []
        setReloadData((prev) => !prev);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setInvoiceToDeleteId(null);
        setDeleteConfirmationText('');
    };

    const handleOpenDeleteConfirmation = (id) => {
        setDeleteConfirmationOpen(true);
        setInvoiceToDeleteId(id);
        setDeleteConfirmationText('');
    }

    const handleSaveInvoice = async () => {
        setAddLoading(true);
        let data = {
            month: parseInt(newInvoiceData.month),
            year: newInvoiceData.year,
            operator_id: newInvoiceData.operator_id,
            operator_name: newInvoiceData.operator_name,
            bank_id: newInvoiceData.bank_id
        };
        await apiCaller.postApiCall('invoice', data);
        fetchedPagesRef.current = []
        handleReset();
        setAddLoading(false);
        setOpenGenerateInvoiceDialog(false);
        setReloadData((prev) => !prev);
    }

    const handleReset = () => {
        setNewInvoiceData({
            operator_name: '',
            operator_id: '',
            month: '',
            year: ''
        });
        setOpenGenerateInvoiceDialog(false);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '-40px', marginLeft: '10px' }}>
                Invoicing
            </Typography>

            <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={handleGenerateInvoice}
                sx={{ left: '82%', marginTop: 2 }}
            >
                Generate Invoice
            </Button>

            <TableContainer sx={{ maxHeight: 440 }}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        width: '50%', // Make the TextField take the full width
                        marginTop: '15px', // Add bottom margin
                        marginLeft: '5px', // Add left margin
                        marginBottom: '10px'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <Skeleton animation="wave" height={40} width="100%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : (
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id !== 'update' && column.id !== 'delete' && column.id !== 'details' ? (
                                                    column.format && typeof row[column.id] === 'number' ? (
                                                        column.format(row[column.id])
                                                    ) : (
                                                        row[column.id]
                                                    )
                                                ) : (
                                                    column.id === 'details' ? (
                                                        <Button onClick={() => handleLookUp(row.id)} color='primary' variant="outlined">
                                                            Look Up
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => handleDeleteInvoice(row.id)} color='error' variant="outlined">
                                                            Delete
                                                        </Button>
                                                    )
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={50}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count} (${Math.ceil(count / rowsPerPage)} pages)`;
                }}
            />

            <Dialog open={openDetail} onClose={handleCloseDetail} maxWidth="md" fullWidth>
                <DialogTitle>Invoice Detail</DialogTitle>
                <DialogContent id="invoice-detail" >
                    {/* Render additional insights and stats using the selectedRow data */}
                    {selectedRow && (
                        <Grid container spacing={1}>
                            {/* General Information Column */}
                            <Grid item xs={5}>
                                <Typography component="h1" variant="h6">Invoice number {selectedRow.data.id}</Typography>
                                <p>ID: {selectedRow.data.id}</p>
                                <p>Invoice Date: {selectedRow.data.create_date}</p>
                                <p>Period: {selectedRow.data.period}</p>
                                <p>Operator: {selectedRow.data.operator_name}</p>
                                <p>Operator ID: {selectedRow.data.operator_id}</p>
                                <p>Bank ID: {selectedRow.data.bank_id}</p>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Typography component="h1" variant="h6">Detail</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <p style={{ textAlign: 'left' }}>Amount of rounds:</p>
                                        <p style={{ textAlign: 'left' }}>Total Bet:</p>
                                        <p style={{ textAlign: 'left' }}>Player winnings:</p>
                                        <p style={{ textAlign: 'left' }}>GGR:</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{ marginLeft: '10px' }}>{selectedRow.data.total_processed_transactions}</p>
                                        <p style={{ marginLeft: '10px' }}>{selectedRow.data.total_bet}</p>
                                        <p style={{ marginLeft: '10px' }}>{selectedRow.data.player_winnings}</p>
                                        <p style={{ marginLeft: '10px' }}>{selectedRow.data.total_charge}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={downloadInvoice} color="primary">
                        Download Invoice
                    </Button>
                    <Button onClick={handleCloseDetail} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                sx={{ minWidth: 300 }}
            >
                <DialogTitle>Delete Operator</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this Invoice?. It can no tbe undone and the invoice may have already been charge. This action requires the invoice to be regenearted.
                    </Typography>
                    <Typography>
                        Type DELETE to confirm:
                    </Typography>
                    <TextField
                        value={deleteConfirmationText}
                        onChange={(e) => setDeleteConfirmationText(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                    <Button
                        onClick={() => handleConfirmDelete(invoiceToDeleteId)}
                        color="error"
                        disabled={deleteConfirmationText !== 'DELETE'}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openGenerateInvoice} onClose={() => setOpenGenerateInvoiceDialog(false)} sx={{ minWidth: '700', maxWidth: '700', margin: 'auto' }}>
                <DialogTitle>Generate Invoice</DialogTitle>
                <DialogContent>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="month-label">Month</InputLabel>
                                <Select
                                    labelId="month-label"
                                    id="month"
                                    value={newInvoiceData.month}
                                    label="Month"
                                    onChange={(e) => setNewInvoiceData({ ...newInvoiceData, month: e.target.value })}
                                >
                                    {months.map((month) => (
                                        <MenuItem key={month} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="year-label">Year</InputLabel>
                                <Select
                                    labelId="year-label"
                                    id="year"
                                    value={newInvoiceData.year}
                                    label="Year"
                                    onChange={(e) => setNewInvoiceData({ ...newInvoiceData, year: e.target.value })}
                                >
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ope-label">Operator</InputLabel>
                                <Select sx={{ width: '550px' }}
                                    labelId="type-label"
                                    id="ope"
                                    value={newInvoiceData.operator_id}
                                    label="Operator"
                                    onChange={(e) => {
                                        const selectedOperator = e.target.value; // This should be the operator_id
                                        const operator = operators.find(op => op.operator_id === selectedOperator);
                                        setNewInvoiceData({
                                            ...newInvoiceData,
                                            operator_name: operator.name,
                                            operator_id: selectedOperator
                                        });
                                    }}
                                >
                                    {operators.map((operator) => (
                                        <MenuItem key={operator.id} value={operator.operator_id}>
                                            {`${operator.name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ope-label">Client</InputLabel>
                                <Select sx={{ width: '550px' }}
                                    labelId="type-label"
                                    id="client"
                                    value={newInvoiceData.bank_id}
                                    label="Client"
                                    onChange={(e) => {
                                        const selectedClient = e.target.value;
                                        const client = clients.find(cl => cl.bank_id === selectedClient);
                                        setNewInvoiceData({
                                            ...newInvoiceData,
                                            bank_id: client.bank_id
                                        });
                                    }}
                                >
                                    {clients.map((client) => (
                                        <MenuItem key={client.id} value={client.bank_id}>
                                            {`${client.name}: ${client.url}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => handleReset()}>Cancel</Button>

                    <Button onClick={handleSaveInvoice} color="primary">
                        {addLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

        </Paper>
    )
}