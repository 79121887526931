import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import CardContent from '@mui/material/CardContent';
import * as apiCaller from '../../../util/ApiCaller';
import CircularProgress from '@mui/material/CircularProgress';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

export default function Summarization() {

    const [months] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [years, setYears] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [summary, setSummary] = useState({
        ggr: { key: 'ggr', title: 'GGR', value: 0, isLoading: true, type: "number" },
        earnings: { key: 'earnings', title: 'Earnings', value: 0, isLoading: true, type: "number" },
        totalBet: { key: 'totalBet', title: 'Total Bet', value: 0, isLoading: true, type: "number" },
        totalLosses: { key: 'totalLosses', title: 'Total players losses', value: 0, isLoading: true, type: "number" },
        totalWins: { key: 'totalWins', title: 'Total players wins', value: 0, isLoading: true, type: "number" },
        amountOfBets: { key: 'amountOfBets', title: 'Total amount of bets', value: 0, isLoading: true, type: "number" },
        mostPlayedGame: { key: 'mostPlayedGame', title: 'Most played game', value: "N/A", isLoading: true, type: "string" },
        mostPlayedGameRounds: { key: 'mostPlayedGameRounds', title: 'Most played game total of rounds ', value: 0, isLoading: true, type: "number" },
        leastPlayedGame: { key: 'leastPlayedGame', title: 'Least played game', value: "N/A", isLoading: true, type: "string" },
        leastPlayedGameRounds: { key: 'leastPlayedGameRounds', title: 'Least played game total of rounds', value: 0, isLoading: true, type: "number" },
        totalRounds: { key: 'totalRounds', title: 'Total of rounds in all games', value: 0, isLoading: true, type: "number" },
        amountOfGamesPlayed: { key: 'amountOfGamesPlayed', title: 'Total of games Played', value: 0, isLoading: true, type: "number" },
        incomeStimation: { key: 'incomeStimation', title: 'This month stimated income', value: 0, isLoading: true, type: "number" }
    });
    const [loading, setLoading] = useState(false);
    const [searchTrigger, setSearchTrigger] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const fetchedSummary = await apiCaller.getApiCall('insigths/summary', `${selectedYear}/${selectedMonth}`);
            const updatedSummary = {};
            for (let key in summary) {
                updatedSummary[key] = {
                    title: summary[key].title,
                    value: fetchedSummary[summary[key].key],
                    isLoading: false,
                    type: summary[key].type
                };
            }
            setSummary(updatedSummary);
            setLoading(false);
        };

        if (searchTrigger) {
            fetchData();
            setSearchTrigger(false);
        }
    }, [searchTrigger]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const date = new Date();
            const year = date.getFullYear();
            const yearsToAdd = [];
            for (let yearToAdd = year - 5; yearToAdd <= year + 5; yearToAdd++) {
                yearsToAdd.push(yearToAdd);
            }
            setYears(yearsToAdd);
            setSelectedYear(year.toString());
            setSelectedMonth((date.getMonth() + 1).toString());
            setSearchTrigger(true);
        };
        fetchData();
    }, []);

    const handleSearch = () => {
        setSummary({
            ggr: { key: 'ggr', title: 'GGR', value: 0, isLoading: true, type: "number" },
            earnings: { key: 'earnings', title: 'Earnings', value: 0, isLoading: true, type: "number" },
            totalBet: { key: 'totalBet', title: 'Total Bet', value: 0, isLoading: true, type: "number" },
            totalLosses: { key: 'totalLosses', title: 'Total players losses', value: 0, isLoading: true, type: "number" },
            totalWins: { key: 'totalWins', title: 'Total players wins', value: 0, isLoading: true, type: "number" },
            amountOfBets: { key: 'amountOfBets', title: 'Total amount of bets', value: 0, isLoading: true, type: "number" },
            mostPlayedGame: { key: 'mostPlayedGame', title: 'Most played game', value: "N/A", isLoading: true, type: "string" },
            mostPlayedGameRounds: { key: 'mostPlayedGameRounds', title: 'Most played game total of rounds ', value: 0, isLoading: true, type: "number" },
            leastPlayedGame: { key: 'leastPlayedGame', title: 'Least played game', value: "N/A", isLoading: true, type: "string" },
            leastPlayedGameRounds: { key: 'leastPlayedGameRounds', title: 'Least played game total of rounds', value: 0, isLoading: true, type: "number" },
            totalRounds: { key: 'totalRounds', title: 'Total of rounds in all games', value: 0, isLoading: true, type: "number" },
            amountOfGamesPlayed: { key: 'amountOfGamesPlayed', title: 'Total of games Played', value: 0, isLoading: true, type: "number" },
            incomeStimation: { key: 'incomeStimation', title: 'This month stimated income', value: 0, isLoading: true, type: "number" }
        });
        setLoading(true);
        setSearchTrigger(true);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const renderSummaryCard = (title, value, isLoading, type) => {
        let percentage = (value / 100) * 100;

        return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={title}>
                <Card sx={{ minWidth: 200, margin: 2 }}>
                    <CardContent>
                        <Typography variant="h7" component="div" sx={{ marginBottom: 2 }}>
                            {title}
                        </Typography>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" color="text.secondary" sx={{ marginRight: 2 }}>
                                    {value}
                                </Typography>
                                {type === 'number' ? (
                                    <CircularProgress
                                        variant="determinate"
                                        value={percentage}
                                        size={20}
                                        sx={{
                                            color: percentage > 70 ? '#00C49F' : percentage > 30 ? '#FFD700' : '#FF4500',
                                            transition: 'transform 1s ease-in-out',
                                            transform: `scale(${percentage / 100})`,
                                        }}
                                    />
                                ) : (
                                    <SportsEsportsIcon fontSize="small" />
                                )}
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Box sx={{ width: '100%', height: 'auto', display: 'grid', alignItems: 'center', padding: 2 }}>
            <Box sx={{ marginLeft: '16px', marginBottom: 2 }}>
                <Typography variant="h4" sx={{ marginBottom: 5 }}>Period:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <FormControl sx={{ minWidth: 100 }}>
                        <InputLabel id="month-select-label">Month</InputLabel>
                        <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={selectedMonth}
                            label="Month"
                            onChange={handleMonthChange}
                        >
                            {months.map((month) => (
                                <MenuItem key={month} value={month}>
                                    {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography sx={{ marginLeft: 2, marginRight: 2 }}>/</Typography>
                    <FormControl sx={{ minWidth: 150 }}>
                        <InputLabel id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            value={selectedYear}
                            label="Year"
                            onChange={handleYearChange}
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
                    Search
                </Button>
            </Box>
            <Grid container spacing={2}>
                {Object.entries(summary).map(([key, { title, value, isLoading, type }]) => (
                    renderSummaryCard(title, value, isLoading, type)
                ))}
            </Grid>
        </Box>
    );
}
