import './App.css';
import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Login from './component/Login';
import Dashboard from './component/Dashboard';
import AskForAccess from './component/AskForAccess';
import ForgotPassword from './component/ForgotPassword'
import StatisticsLookUp from './component/StattisticsLookup';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserProfile from './component/dashboardComponents/UserProfile';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' Component={Login}></Route>
				<Route exact path='/forgotpassword' Component={ForgotPassword} ></Route>
				<Route exact path='/askaccess' Component={AskForAccess}></Route>
				<Route exact path='/statisticslookup' Component={StatisticsLookUp}></Route>
				<Route exact path='/dashboard' Component={Dashboard}></Route>
				<Route exact path='/profile' Component={UserProfile}></Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
